import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import QuestionAnswer from './questionAnswer';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';


const SortableItem = SortableElement(({ value }) => <li>{value}</li>);

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value.respuesta} />
            ))}
        </ul>
    );
});


class AnswerSortable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    componentWillMount() {
        const { items } = this.props;
        // console.log(items)
        this.setState({ items })
    }

    onSortEnd({ oldIndex, newIndex }) {
        const { updateItems } = this.props;

        let newPrioritization = arrayMove(this.state.items, oldIndex, newIndex);

        this.setState({
            items: newPrioritization
        })

        updateItems(newPrioritization);
    };

    render() {
        return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
    }

}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AnswerSortable);
