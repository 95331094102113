import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TimeRange from 'react-time-range';
import moment from 'moment';

class TimeRangeQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: undefined,
            endTime: undefined
        }

        this.setRange = this.setRange.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
    }

    nextQuestion() {
        const { nextQuest, question } = this.props;


        if (!this.state.startTime || !this.state.endTime)
            return;

        nextQuest(question, [this.state.startTime, this.state.endTime]);
    }
    setRange({ endTime, startTime }) {
        this.setState({
            startTime: startTime,
            endTime: endTime
        })
    }


    render() {
        const { question: { quest, desc } } = this.props;

        return (
            <div className="content-time-range-question">

                <h2>{quest}</h2>
                <p>{desc}</p>
                <div className="content-time-range">
                    <TimeRange
                        startLabel="Inicio"
                        endLabel="Fin"
                        minuteIncrement={60}
                        sameIsValid={true}
                        showErrors={false}
                        startMoment={this.state.startTime}
                        endMoment={this.state.endTime}
                        onChange={this.setRange} />

                </div>

                <button className={`callback ${this.state.startTime && this.state.endTime ? "" : "disable"}`} onClick={this.nextQuestion} >Siguiente</button>
            </div>
        )
    }
}




const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeRangeQuestion);