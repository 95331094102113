import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getContextFeedback, setCurrentContextCategoryFeedback } from '../actions/actions'


class Feedback extends Component {
    constructor(props) {
        super(props)
        this.setCurrentContextCategory = this.setCurrentContextCategory.bind(this);
        this.getCategories = this.getCategories.bind(this);
    }


    componentWillMount() {
        const { actor, history, getContextFeedback, contexts } = this.props;
        if (!actor.id) {
            history.push("/");
            return;
        }
        if (contexts.length === 0) {
            getContextFeedback(actor.p_team_actors.map(ta => ta.idTeam))
        }

    }

    componentDidMount() {
        // setTimeout(() => {
        //     Object.keys(this.refs)
        //         .map(ref => this.refs[ref])
        //         .forEach(element => {
        //             let maxScroll = element.scrollWidth - element.clientWidth;
        //             let halfScroll = maxScroll / 2;
        //             element.scrollLeft = Math.floor(Math.random() * (halfScroll + 20)) + (halfScroll - 20)
        //         });
        // }, 1000);

    }

    setCurrentContextCategory(context, category) {
        const { setCurrentContextCategoryFeedback, history } = this.props;

        setCurrentContextCategoryFeedback({
            context, category
        })

        history.push("/home/feedbackDetail/");
    }

    getCategories(categories) {


        let _categorues = categories.reduce((a, b) => {
            a[b.nombre] = Object.assign([], a[b.nombre]);
            a[b.nombre].push(b);
            return a;
        }, {})

        return Object.keys(_categorues).map(name => {
            return _categorues[name].sort((a, b) => a.id - b.id).reduce((a, b) => b, {})
        })

    }

    render() {
        const { actor, contexts } = this.props;

        return (
            <div className="content-feedback">
                <h2> Comentarios </h2>
                <div className="content-feedback-context">
                    {contexts.map(context => (
                        <div className="feedback-context">
                            <h5>{context.contexto}</h5>
                            <div ref={'feedbackContent' + context.id} className="feedback-category-content">
                                {
                                    this.getCategories(context.p_feedback_categories).map(cate => (
                                        <div className="feedback-category-item" onClick={() => this.setCurrentContextCategory(context, cate)}>
                                            <span>{cate.nombre} </span>
                                            <small> {cate.descripcion}  </small>
                                        </div>
                                    ))
                                }
                            </div>

                            {/* <FA name="bolt" /> */}
                        </div>
                    ))}

                </div>


            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    contexts: state.feedback.contexts
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getContextFeedback,
        setCurrentContextCategoryFeedback
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
