import React, { Component } from 'react';
import { connect } from 'react-redux';
class Redirect extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() { 
        const { currentHistory, route } = this.props;
        currentHistory.push(route);
    }


    render() {
        return (<div/>);
    }
}


export default Redirect;
