import { UPDATE_FOOTER_REDUCER, UPDATE_FOOTER_VALUE_REDUCER,
    ENABLE_FOOTER_NEXT_REDUCER, DISABLE_FOOTER_NEXT_REDUCER } from '../constantsGlobal';

const initialState = {
    type: "",
    value: "",
    childs: [],
    allowChange: false
}

function footer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FOOTER_REDUCER:
            return Object.assign({}, state, {
                type: action.payload.type,
                childs: action.payload.childs,
                value: action.payload.value,
                allowChange: true
            });
        case UPDATE_FOOTER_VALUE_REDUCER:
            return Object.assign({}, state, {
                value: action.payload
            });
        case ENABLE_FOOTER_NEXT_REDUCER:
            return Object.assign({}, state, {
                allowChange: true
            });
        case DISABLE_FOOTER_NEXT_REDUCER:
            return Object.assign({}, state, {
                allowChange: false
            });
        default:
            return state
    }
}

export default footer;