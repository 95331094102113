import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FA from 'react-fontawesome';
import { NavLink } from 'react-router-dom'

import { setDefinitionItem } from '../actions/actions'
import { definitions } from '../resources/resources'

import { enumFeedbackContext } from '../constantsGlobal'

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: {}
        }

        this.refresModule = this.refresModule.bind(this);
        this.callFeedback = this.callFeedback.bind(this);
    }

    refresModule() {
        window.setTimeout(() => this.setState({
            current: (new Date())
        }), 1000)
    }

    callFeedback() {
        const { setDefinitionItem } = this.props;
        setDefinitionItem({
            ...definitions.feedback,
            contextId: enumFeedbackContext.fdbkExt.id,
            categoryId: enumFeedbackContext.fdbkExt.category.feedback,
            origin: "/home/profile/"
        });

        this.refresModule();
        // history.push("/home/feedbackDetail/");

    }


    render() {

        return (
            <aside>
                <nav className="menu">

                    <section className="section-menu">
                        <NavLink exact to={`/home/profile/`} activeClassName="active" onClick={this.refresModule}>
                            <span>Perfil</span>
                        </NavLink>
                    </section>


                    <section className="section-menu">
                        <span className="caption">Ruta</span>
                        <NavLink exact to={`/home/retrospective/`} activeClassName="active" onClick={this.refresModule}>
                            <span>Retrospectiva</span>
                        </NavLink>
                        <NavLink exact to={`/home/breathingTools/`} activeClassName="active" onClick={this.refresModule}>
                            <span>Respiracion</span>
                        </NavLink>
                        {/*   */}
                        <NavLink exact to={`/home/definition/`} activeClassName="active" onClick={this.callFeedback}>
                            <span>Feedback</span>
                        </NavLink>
                        <NavLink exact to={`/home/scheduler/`} activeClassName="active" onClick={this.refresModule}>
                            <span>Planificador</span>
                        </NavLink>
                        {/* <NavLink exact to={`/home/habilidades/`} activeClassName="active" onClick={this.refresModule}>
                            <span>Como me veo</span>
                        </NavLink> */}
                    </section>





                </nav>

                <footer>
                    <span>©copyright 2018</span>
                </footer>
            </aside>

        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
    setDefinitionItem
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
