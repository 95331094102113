import ReduxSagaFirebase from 'redux-saga-firebase'
import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAMUfaROjMcZhV2SJijIcW-PGeKTRiDFkg",
    authDomain: "biotica-362a3.firebaseapp.com",
    databaseURL: "https://biotica-362a3.firebaseio.com",
    projectId: "biotica-362a3",
    storageBucket: "biotica-362a3.appspot.com",
    messagingSenderId: "456606469474",
    appId: "1:456606469474:web:3aaf4f0daeac836bbaf03b" 
  });
  
  const rsf = new ReduxSagaFirebase(firebaseApp);

  // const messaging = firebase.messaging();

  // messaging.getToken().then((currentToken) => {
  //   console.log(currentToken)
  //   // if (currentToken) {
  //   //   sendTokenToServer(currentToken);
  //   //   updateUIForPushEnabled(currentToken);
  //   // } else {
  //   //   // Show permission request.
  //   //   console.log('No Instance ID token available. Request permission to generate one.');
  //   //   // Show permission UI.
  //   //   updateUIForPushPermissionRequired();
  //   //   setTokenSentToServer(false);
  //   // }
  // }).catch((err) => {
  //   console.log('An error occurred while retrieving token. ', err);
  //   // showToken('Error retrieving Instance ID token. ', err);
  //   // setTokenSentToServer(false);
  // });


  export default rsf