import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class QuestionAnswer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { answer, selectAnswer } = this.props;
        return (
            <li onClick={() => selectAnswer(answer.id)}>
                {answer.respuesta}
            </li>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswer);
