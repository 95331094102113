import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import './App.css';
import './styles/themeplate.css';
import './styles/login.css';
import './styles/retrospective.css';
import './styles/breathingsTools.css';
import './styles/definition.css';
import './styles/feedbackDetail.css';
import './styles/scheduler.css';

import Login from "./login";
import Home from './themeplate/home'

import { AnimatedSwitch } from 'react-router-transition';

// HashRouter as Router,
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'


import { BASE_ROUTE } from './constantsGlobal'

class App extends Component {

  render() {

    return (<Router basename={BASE_ROUTE}>
      {/* <TransitionSwitch className="example2" duration={200}> */}
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route exact path='/' component={Login}></Route>
        <Route path='/home/:component' component={Home}></Route>

      </AnimatedSwitch>
      {/* </TransitionSwitch> */}
    </Router>);
  }
}


const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}
// getAllQuestios


export default connect(mapStateToProps, mapDispatchToProps)(App);