import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FA from 'react-fontawesome';

class PickerOptionsQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            optionsPicker: {}
        }
        this.setNewValuePicker = this.setNewValuePicker.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
    }

    setNewValuePicker(e, option) {

        let optionPicker = this.state.optionsPicker;
        let __value = e.currentTarget.checked ? 1 : 0;
        optionPicker[option] = __value;

        this.setState({
            optionsPicker: optionPicker
        })
    }

    nextQuestion() {
        const { nextQuest, question } = this.props;
        let hasItems = Object.keys(this.state.optionsPicker).reduce((a, b) => this.state.optionsPicker[b] + a, 0)

        if (hasItems <= 0)
            return;

        nextQuest(question, { ...this.state.optionsPicker });

        this.setState({
            optionsPicker: {}
        })
    }

    componentWillUnmount() {
        this.setState({
            optionsPicker: {}
        })
    }

    render() {
        const { question: { quest, desc, options } } = this.props;

        let hasItems = Object.keys(this.state.optionsPicker).reduce((a, b) => this.state.optionsPicker[b] + a, 0)

        return (
            <div className="content-picker-options-question">

                <h2>{quest}</h2>
                <p>{desc}</p>
                <div className="content-options-picker">
                    {options.map((item, indx) => (<div key={`chk_${indx}`}>
                        <label for={`chk_${indx}`}>
                            <div className="content-picker">
                                <input id={`chk_${indx}`} checked={this.state.optionsPicker[item.id] ? this.state.optionsPicker[item.id] === 1 : false} type="checkbox" onChange={(e) => this.setNewValuePicker(e, item.id)} />

                                {/* <div>
                                <FA name={"minus"} onClick={() => this.setNewValuePicker(item.id, "minus")} />
                                <FA name={"plus"} onClick={() => this.setNewValuePicker(item.id, "plus")} />
                            </div>
                            <span>{this.state.optionsPicker[item.id] ? this.state.optionsPicker[item.id] : 0} <small>pt</small></span> */}
                            </div>
                            <span>{item.text}</span>
                        </label>

                    </div>))}
                </div>

                <button className={`callback ${hasItems <= 0 ? 'disable' : ''}`} onClick={this.nextQuestion} >Siguiente</button>

            </div>
        )
    }
}


const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(PickerOptionsQuestion);