import { GET_ALL_INTERSTEDS_ACTORS_SERVICE } from "../constantsGlobal";

export const detalleAnhelos = {
    "1": {
        "name": "Personal",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": " Expresa las ideas de una forma segura, es metódico y toma pocos riesgos, es apasionado, suele casarse con una sola idea y defender su postura, teme estar equivocado el manejar información de forma despacio y asimilar poco a poco ayuda a disipar el sentimiento de abrumación que tiene en ocasiones",
            "level": 3
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": " Es un líder exigente, está en función de ser la mejor versión,  el ver resultados le motiva más que nada, y los procesos lentos hace que pierda el foco, es un líder inmediatista, y puede sentirse fácilmente frustrado con los procesos puede caer en el error de sobre exigir el no avanzar al ritmo deseado, le hace cuestionar su propósito y afecta su autoestima",
            "level": 6
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Es del tipo autodidacta, comprometido, mientras esté encaminado hacia un objetivo, va a estar tranquilo, la diversidad de conocimiento juega en su contra, cuestionando el tiempo invertido en procesos que no son de su interés se siente cómodo refinando conocimiento, pero no es de los que se queden en un tema, la estaticidad de las cosas juega en contra cuando siente que hay demasiada información",
            "level": 5
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Funciona como mentor, pero siempre superpone sus necesidades a las de los demás, es impaciente, y juzga el desarrollo de los demás, tiene un pensamiento crítico natural su foco es trabajo por objetivos le desespera desperdiciar su tiempo haciendo actividades monótonas o no haciendo nada",
            "level": 3
        }
    },
    "2": {
        "name": "Social",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "Este anhelo tiene dos extremos, frente a la comunicación un extremo, es laxo con sus ideas y sigue la corriente de figuras superiores, y la segunda es imponer sus ideas frente a sus iguales y personas a cargo, es bueno escuchando y analizando entornos su precaución a tomar decisiones erradas que afecte su aprobación lo hacen un buen validador y le permite tener una visión holística de una forma natural a menudo sobre piensa, pero no comparte sus pensamientos por miedo a la desaprobación suele ser muy conciso en los mensajes, es fundamental para el expresar sus ideas claras y que sienta que generen aporte le gusta llamar la atención pero no le gusta sentir que es juzgado",
            "level": 6
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Es un líder de direccion, aporta su visión y es muy fácil generar un camino de ejecución bajo su criterio - (ego) analiza constantemente las situación del entorno, intentando prever cambios es un líder propositivo, plantea soluciones según su punto de vista No acepta juicios hacia el que a su parecer sean equivocados, su reacción es molestia - le cuesta bastante ser líder, y estar a cargo le genera satisfacción por el concepto de tener el poder - status este líder tiende a ser laxo con sus superiores, y buscar la aprobación de sus iguales",
            "level": 5
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Explorar temas de interés común, le es cómodo el desarrollo de conocimiento para generar una estructura de carrera, y de crecimiento profesional, a través de escalafones le motiva, esta en busca de ascender todo lo que sea acerca de conocimiento validado necesita certeza sobre tener más del conocimiento necesario para poder pertenecer al grupo y marcar diferencia",
            "level": 3
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Es fundamental el clima, es necesario sentirse aceptado sentir que hace parte de un grupo le genera la tranquilidad para poder expresar sus ideas está constantemente sondeando el comportamiento de las personas que lo rodean le resulta fácil co crear, y trabajar en grupos durante el trabajo en grupo para esta persona, es necesario tener una guia, al trabajar con iguales, ser laxo puede llevar a generar frustración dentro de sí mismo, por no sentir que hay un norte en la manada",
            "level": 4
        }
    },
    "3": {
        "name": "Estilo de vida",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "Los periodos de comunicación extensos como reuniones, hacen que pierda el foco fácilmente en las reuniones, en su entorno es fácil generar empatía con los demás, la necesidad de apertura y evita que se ciña a una estructura, puede conectar fácilmente con aspectos de la escucha profunda, pero tiende a separar a personas que sientan que son generadoras de fricción expresa las ideas bajo interpretaciones propias, no es afable a mantenerse en marcos de conocimiento en donde deba repetir más que interpretar y crear, defiende la postura de lo ligero, práctico, sencillo y que genere valor de forma consciente o inconscientemente, el no estar conforme con estos centros, siempre se expresa de forma verbal o no verbal su metodología de enseñanza se basa más en el auto descubrimiento, dando poca información y permitiendo que el equipo explore, y genere sus propios puntos de vista",
            "level": 4
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Su liderazgo se desarrolla sobre la autogestión, el empoderamiento de cada miembro del equipo y el desarrollo de la sinergia a través de la iniciativa propia, no es bueno teniendo el foco de atención por que tiende a sentirse saturado, y que pierde el foco de las cosas que desea (tiempo, libertad, estar cómodo), al no tener una postura de liderazgo radical, presenta muchos inconvenientes gestionando entornos con muchos egos el no tener resultados rápidos lo estresa, no se entiende muy bien con procesos largos, que le generen la necesidad de tener una visión holística y estar presente en muchos entornos simultáneamente puede caer en el error de la desatención, pensar que los equipos son igual de autónomos que el",
            "level": 4
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Necesita conocimiento práctico, el tener demasiada información le dificulta tomar decisiones o generar conocimiento, su centro está en el pensamiento disruptivo, en cómo hacer buenas preguntas para evitar recorrer largos caminos, se desenvuelve muy bien con temas creativos, ideas y situaciones en donde se necesiten soluciones que rompan paradigmas, y aporten un nuevo foco la resistencia temporal del ahora es su principal filtro, el conocimiento metódico de extensas fuentes lo satura, prefiere pequeñas píldoras que le ayuden a generar un punto de vista, que después validará con otras personas el objetivo del conocimiento es disminuir la incertidumbre, que se representa en esfuerzo, estrés, incomodidad y tempo",
            "level": 6
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "No es de las personas de entrar en conflicto, suele tomar el camino más sencillo y con menor resistencia para ejecutar una tarea, su temporalidad lo ayuda a ser minucioso en los detalles del producto que se genera, los ambientes de tensión en donde su status en el equipo puede verse afectado, tiende a afectar el centro de su motivación, se le dificulta mantener un foco de atención por periodos extensos de tiempo es selectivo, decide con quien tener empatía, su deseo de mantener un entorno tranquilo puede hacerlo ajeno y apático en algunas ocasiones puede caer en el error de la pereza",
            "level": 5
        }
    },
    "4": {
        "name": "Moral",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "Se comporta como un excelente escuchador, es primordial entender la situación antes de aportar, siente la necesidad de marcar la diferencia en una conversación, no desde el punto de ser reconocido, si no desde el aspecto de que su aporte apoye a al desarrollo de una idea evita los debates, aunque su tendencia está más inclinada hacia escuchar que hacía hablar, no le molestan las conversaciones largas mientras sienta que sus conocimientos pueden marcar una diferencia relevante, aunque tiende a frustrarse cuando no se le tiene en cuenta, o cuando siente que sus aportes son tomados bajo una mirada diferente  al altruismo es un buen maestro, su único interés es transmitir conocimiento, el desinterés en ser reconocido o un tener un puesto superior, permite la apertura de co creación, es un maestro presente, lo temas de autogestión no le sientan muy bien, por la percepción de no estar presente en la evolución de las personas",
            "level": 3
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Es un líder empático, su principal preocupación es el bienestar del equipo, se le dificulta separa contextos laborales y personales, su centro está en ser el mayor apoyo posible para su equipo, cayendo en el sobre trabajo, fomenta la comunicación continua y suele intentar transferir esa filosofía a su equipo, lo que en ocasiones genera dependencias entre  los miembros del equipo suele ser muy laxo cuando se trata de su punto de vista, aunque tiende a ser muy radical, cuando se trata de defender la postura de su equipo su mente está en el ahora, aunque posee un buena visión holística de los procesos, intenta predecir eventualidades, para evitar generar sobrecargas en miembros de su equipo a cargo, la temporalidad de los procesos no difieren mucho, mientras sienta que es una pieza fundamental del proceso puede pecar en ser un líder sobreprotector, y en buscar culpables al momento de sentirse frustrado por los resultados",
            "level": 3
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Focaliza sus esfuerzos de adquirir conocimiento en, conocimiento para otros, siente la necesidad de aprender formas o adquirir conocimiento en pro de manejarse mejor, o ayudar mejor, o enseñar mejor su dinámica de aprendizaje está dada hacia lo social, se comporta muy bien recibiendo conocimiento de comunidades, conferencias, uno a uno o vídeos la finalidad de su esfuerzo adquiriendo conocimiento está enfocada hacia el impacto, lo que se, a quien le sirve, o que puedo crear que impacte la vida de otras personas",
            "level": 3
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Actúa por el bien del equipo, suelen sobrecargarse de trabajo y suelen superponer las necesidades de sus compañeros a las propias, tiene un alto sentido del compromiso, y deber no es muy recomendable que trabaje con motivadores (estilo de vida), al sentir que es el único que puede ayudar se sobrecarga de esfuerzo el exceso de altruismo, actúa en contra aunque se sienta cómodo con sentir que genera impacto, descuidar su tiempo personal, lo afecta, entre otras cosas el que sus ideas no sean escuchadas o sienta que no es relevante en un equipo, o que tiene la capacidad, cargo o conocimiento para aportar de forma relevante a un proceso lo frustran, no es de las personas que se siente bien solo investigando, su foco es el impacto a través de acciones",
            "level": 4
        }
    },
    "5": {
        "name": "Practical",
        "comunicacion": {
            "name": "Comunicación",
            "descripcion": "En su mayor parte se mantiene en un nivel de escucha poco empática, tienen a filtrar y razonar sobre cada fragmento de información que ingresa, mantener la concentración en sesiones largas no le es complejo, mientras no se abarque demasiados temas, ya que cada parte de la información intenta conectarla con un contexto y plantear posibles realidades a partir de la nueva información capturada  objetivo al momento de expresar ideas, prefiere ser conciso y extenderse poco, suele ser tajante y metódico con las ideas que expresa,  la forma de expresar su visión está enfocado a vender su punto de vista esto es provocado por la necesidad de controlar el entorno  método de enseñanza es a partir de la experiencia propia, no se siente cómodo compartiendo conocimiento que no haya experimentado antes y que esté seguro de su veracidad",
            "level": 4
        },
        "liderazgo": {
            "name": "Liderazgo",
            "descripcion": "Es un líder observador, en ocasiones puede pecar al ser inflexible en los objetivos, su planteamiento es \"hay que lograrlo\", su constante necesidad de tener el control, lo lleva a tener una visión holística de todo el proceso, aunque en ocasiones suele reaccionar de forma negativa ante la pérdida del control de sus variables es muy bueno manejando egos, al evitar ser empático y manejar los aportes de una forma objetiva, le permite dividir efectivamente responsabilidades y reconocimientos su temporalidad está en el largo plazo, está en función de lograr una meta, y de un camino lleno de incertidumbres controladas puede caer en la petulancia, al ignorar opiniones de otras personas por mantenerse sujeto a su plan de ruta probado y sobre pensado, en ocasiones su inflexibilidad puede tomarse como sobreexigencia, y su objetividad en ocasiones puede limitar la empatía con otros miembros de su equipo a cargo",
            "level": 4
        },
        "capacidadAprendizage": {
            "name": "Capacidad de aprendizaje",
            "descripcion": "Su foco de aprendizaje está enfocado en su situación actual, y en tener la información suficiente para poder generar un punto de vista y reaccionar, fluye muy bien con los procesos investigativos, creación y formulación de propuestas, adaptarse a nuevos modelos de información no le es complejo, su modelo mental funciona relacionando y generando nuevos puntos de vista se entiende mas con fuentes de información escritas en donde pueda tener una visión completa de un contexto, y buscar de forma más orgánica la información, en el proceso de aprendizaje intenta potenciar el recurso del tiempo, aunque no rechaza las investigaciones extensas o contenido denso lo toma como una herramienta para solucionar problemas, no le importa compartirlo, pero generalmente no es de los que vaya preguntando a las personas si lo necesitan",
            "level": 5
        },
        "trabajoEquipo": {
            "name": "Trabajo en equipo",
            "descripcion": "Cada tarea, tiene un inicio y un fin, plantea una ruta e intenta ceñirse a ella, intenta que sus compañeros se ajusten a sus reglas, para sufrir la menor cantidad de variables posibles en su plan, intenta controlar sus reacciones con otras personas, porque esto puede llevar a sobre complicaciones en su relación con el grupo, elige que compartir de forma inconsciente y usualmente intenta tener una respuesta para cualquier cosas que puedan preguntarle, de hay la necesidad de tener conocimiento necesario para poder reaccionar en su mayor parte intenta ser independiente, aunque lo hace sin perder la visión de cada miembro del equipo, se proyecta como una persona segura y controlada su capacidad de reacción es particularmente rápida, sin necesidad de observar y plantear escenarios, lo hace tener modelos de respuesta, su factor creativo está enfocada en la solución de problemas, el tener un buen análisis de situaciones le permite evaluar la situación y plantear posibles rutas que puedan ayudar a solucionar la situación",
            "level": 4
        }
    }
};
export const personalInterst = [
    {
        "item": "Deportes",
        "values": [
            {
                "item": "En equipo",
                "values": [
                    "Football",
                    "Basketball",
                    "Volleyball",
                    "Tenis",
                    "Ping pong"
                ]
            }, {
                "item": "Con amigos o solo",
                "values": [
                    "Ciclismo",
                    "Squash",
                    "Escalar",
                    "Artes marciales",
                    "Billar",
                    "Running",
                    "Patinaje",
                    "Natación"
                ]
            }
        ]
    },
    {
        "item": "Conocimiento",
        "values": [
            "Tecnico",
            "Crecimiento personal",
            "Entretenimiento"
        ]
    },
    {
        "item": "Impacto social",
        "values": [
            "Reciclaje",
            "Voluntariados",
            {
                "item": "Ahorro de agua",
                "values": [
                    "En mi hogar",
                    "Uso Termos de Agua",
                    "Uso Mugs"
                ]
            },
            "Ahorro de energía",
            {
                "item": "Transporte ecológico",
                "values": [
                    "Camino",
                    "Uso bicicleta",
                    "Transporte electrico",
                    "Transporte publico"
                ]
            },
            "Siembra de Árboles",
            "Recoger basuras",
            "Productos ecológicos"
        ]
    },
    {
        "item": "Bienestar",
        "values": [
            "Yoga",
            "Meditación",
            "Respiración",
            "Alimentación saludable"
        ]
    }
];

// [
//     {
//         "item": "Deportes",
//         "values": [
//             {
//                 "item": "En equipo",
//                 "values": [
//                     "Football",
//                     "Basketball",
//                     "Volleyball",
//                     "Tenis",
//                     "Ping pong",
//                     "Waterpolo",
//                     "Ultimate"
//                 ]
//             },
//             {
//                 "item": "Con amigos o solo",
//                 "values": [
//                     "Ciclismo",
//                     "Tiro con arco",
//                     "Squash",
//                     "Escalar",
//                     "Artes marciales",
//                     "Billar",
//                     "Running",
//                     "Yoga",
//                     "Meditación",
//                     "Patinaje",
//                     "Gimnasia",
//                     "Natación",
//                     "Skateboard"
//                 ]
//             }
//         ]
//     }, {
//         "item": "Música",
//         "values": [
//             "Clásica",
//             "Pop",
//             "Rock",
//             "Electrónica",
//             "Hip-hop",
//             "Jazz",
//             "Blues",
//             "Metal",
//             "Punk",
//             "Reggae",
//             "Soul",
//             "Urbano",
//             "Boleros",
//             "Tangos",
//             "Vallenatos",
//             "Rancheras"
//         ]
//     }, {
//         "item": "Arte",
//         "values": [
//             "Gimnasia",
//             "Pintura",
//             "Fotografía",
//             "Baile",
//             "Arte plástico",
//             "Arquitectura",
//             "Cine",
//             "Maquillaje profesional"
//         ]
//     }, {
//         "item": "Literatura",
//         "values": [
//             "Terror",
//             "Realista",
//             "Autobiográfico",
//             "Formación",
//             "Fantasía",
//             "Pulp fiction",
//             "Misterio",
//             "Picaresca"
//         ]
//     }, {
//         "item": "Impacto social",
//         "values": [
//             "Reciclaje",
//             "Voluntariados",
//             {
//                 "item": "Ahorro de agua",
//                 "values": [
//                     "En mi hogar",
//                     "Uso Termos de Agua",
//                     "Uso Mugs"
//                 ]
//             },
//             "Ahorro de energía",
//             {
//                 "item": "Transporte ecológico",
//                 "values": [
//                     "Camino",
//                     "Uso bicicleta",
//                     "Transporte electrico",
//                     "Transporte publico"
//                 ]
//             },
//             "Siembra de Árboles",
//             "Recoger basuras",
//             "Productos ecológicos"
//         ]
//     }
// ]


export const imagesResources = {
    "Deportes": "/images/deportes.jpeg",
    "Música": "/images/musica.jpeg",
    "Arte": "/images/arte.jpeg",
    "Literatura": "/images/literatura.jpeg",
    "Conocimiento": "/images/literatura.jpeg",
    "Impacto social": "/images/ambiente.jpeg",
    "Bienestar": "/images/bienestar.jpg"
};

export const definitions = {
    feedback: {
        title: "Feedback",
        description: "Retro alimenta a tu equipo",
        // note: "",
        steps: [
            {
                title: "Objetivo",
                description: "Ten presente cuál es el objetivo de tu feedback."
            },
            {
                title: "Planificación",
                description: "Antes de comenzar visualiza la conversación, comienza haciendo preguntas que generen apertura."
            },
            {
                title: "Escucha",
                description: "Evalúa el contexto de la conversación y da tu opinión frente a los hechos, teniendo en consideración las emociones de ambas partes."
            },
            {
                title: "Lenguaje",
                description: "Brinda opciones de mejora, puntos a tener en cuenta, evita palabras que tengan connotación negativa."
            },
        ]
    },
    breathing: [
        {
            title: "Foco y Tensiones",
            description: "Logra que la atención se centre solo en la respiración.",
            note: "Tu mente empezará a buscar distractores, mantente firme en este ejercicio de respiración!",
            steps: [
                {
                    title: "Inhalación",
                    description: "Inhala profundo tomando aire por la nariz desde el estómago y luego exhala"
                },
                {
                    title: "Sostener",
                    description: "Mantén el aire en los pulmones por unos pocos segundos, deja que el cuerpo se relaje."
                },
                {
                    title: "Exhalación",
                    description: "Una vez más toma aire desde la nariz, exhalando por la boca"
                }
            ]
        },
        {
            title: "Memoria",
            description: "Usa este ejercicio cuando hayas olvidado algo, (repite tres veces)",
            note: "Si en algún momento te resulta angustioso, suéltalo y vuelve a empezar",
            steps: [
                {
                    title: "Inhalación",
                    description: "Deja que tu vientre se relaje y se infle, los hombros pierden la tensión y se aflojan."
                },
                {
                    title: "Sostener",
                    description: "Retén el aire todo el tiempo que puedas."
                },
                {
                    title: "Exhalación",
                    description: "suéltalo lentamente"
                }
            ]
        },
        {
            title: "Relajación",
            description: "Controla la tensión de tu cuerpo a través de la respiración.",
            note: "La clave para marcar la pausa entre dos respiraciones es mantenerse consciente y centrado, porque si la mente se distrae vuelve fácilmente a su patrón contraído",
            steps: [
                {
                    title: "Inhalación",
                    description: "Respiración a través de la nariz es lenta y tranquila"
                },
                {
                    title: "Exhalación",
                    description: "Al llegar al punto máximo de inhalación, libera lentamente el aire por la boca abierta."
                },
                {
                    title: "Sostener",
                    description: "Luego, sin cerrar la boca y con la mandíbula relajada, haz una pausa y espera conscientemente hasta que el cuerpo necesite volver a respirar."
                },
                {
                    title: "Destensionamiento",
                    description: "Después de dos o tres respiraciones, permite que el tiempo entre una y otra respiración sea un momento de relajación total para tu organismo."
                },
                {
                    title: "Foco",
                    description: "Luego, respira enfocado en una zona concreta de tu cuerpo, que necesite relajarse especialmente."
                }

            ]
        },
        {
            title: "Estres y sueño",
            description: "...",
            note: "Realizar este ejercicio dos veces al día, Haz cuatro respiraciones por sesion",
            steps: [
                {
                    title: "Posicion de la lengua",
                    description: "Coloca la punta de la lengua en el paladar, detrás de los dientes incisivos superiores"
                },
                {
                    title: "Inhalación",
                    description: "Inhala por la nariz durante 4 segundos"
                },
                {
                    title: "Sostener",
                    description: "Aguanta la respiración 7 segundos."
                },
                {
                    title: "Exhalación",
                    description: "Exhala por la boca frunciendo los labios y haciendo ruido (como si soplaras) durante 8 segundos."
                }
            ]
        },
        {
            title: "Equilibrar la mente",
            description: "Controla la saturación de tus pensamientos.",
            note: "Realizar este ejercicio durante algunos minutos",
            steps: [
                {
                    title: "Preparación",
                    description: "Tapa con el pulgar uno de los orificios de la nariz"
                },
                {
                    title: "Inhalación",
                    description: "Respira lentamente por la otra fosa nasal durante 8 segundos"
                },
                {
                    title: "Sostener",
                    description: "Retén el aire 4 segundos."
                },
                {
                    title: "Exhalación",
                    description: "Tapas la otra fosa nasal y exhalas durante 8 segundos"
                },
                {
                    title: "Pausa",
                    description: "Haz una pausa de 4 segundos"
                },
                {
                    title: "Reinicia",
                    description: "Inhala a hora, por la fosa nasal con la que exalaste el aire."
                }
            ]
        }
    ]
}


export const typeQeuestionsSaturation = {
    "timeRange": 1,
    "pickerOptions": 2,
    "timeRangeOptionsField": 3,
    "timeRangeTodo": 4
}

export const staturationQuestions = [
    {
        id: 1,
        quest: "¿En qué momentos del día tengo más energía?",
        order: 1,
        clout: 0.7,
        cloutLimits: [0.5, 0.5],
        options: [
            { id: 1, text: "6am a 9am", min: 6, max: 9 },
            { id: 2, text: "9am a 12pm", min: 9, max: 12 },
            { id: 3, text: "12pm a 3pm", min: 12, max: 3 },
            { id: 4, text: "3pm a 6pm", min: 15, max: 18 },
            { id: 5, text: "6pm a 9pm", min: 18, max: 21 }
        ],
        type: typeQeuestionsSaturation.pickerOptions
    },
    {
        id: 2,
        quest: "¿En qué momentos del día tengo mayor capacidad de concentración?",
        order: 2,
        clout: 0.7,
        cloutLimits: [0.5, 0.5],
        options: [
            { id: 1, text: "6am a 9am", min: 6, max: 9 },
            { id: 2, text: "9am a 12pm", min: 9, max: 12 },
            { id: 3, text: "12pm a 3pm", min: 12, max: 3 },
            { id: 4, text: "3pm a 6pm", min: 15, max: 18 },
            { id: 5, text: "6pm a 9pm", min: 18, max: 21 }
        ],
        type: typeQeuestionsSaturation.pickerOptions
    },
    {
        id: 3,
        quest: "¿En qué momento del día siento más estres?",
        order: 3,
        clout: 1,
        cloutLimits: [0, 0],
        options: [
            { id: 1, text: "6am a 9am", min: 6, max: 9 },
            { id: 2, text: "9am a 12pm", min: 9, max: 12 },
            { id: 3, text: "12pm a 3pm", min: 12, max: 3 },
            { id: 4, text: "3pm a 6pm", min: 15, max: 18 },
            { id: 5, text: "6pm a 9pm", min: 18, max: 21 }
        ],
        type: typeQeuestionsSaturation.pickerOptions
    },
    {
        id: 4,
        quest: "¿En que momento del dia siento más ansiedad?",
        order: 4,
        clout: 1,
        cloutLimits: [0, 0],
        options: [
            { id: 1, text: "6am a 9am", min: 6, max: 9 },
            { id: 2, text: "9am a 12pm", min: 9, max: 12 },
            { id: 3, text: "12pm a 3pm", min: 12, max: 3 },
            { id: 4, text: "3pm a 6pm", min: 15, max: 18 },
            { id: 5, text: "6pm a 9pm", min: 18, max: 21 }
        ],
        type: typeQeuestionsSaturation.pickerOptions
    },
    {
        id: 5,
        quest: "¿Cual es tu período de sueño?",
        order: 5,
        clout: -1,
        cloutLimits: [0, 0],
        type: typeQeuestionsSaturation.timeRange
    },
    {
        id: 6,
        quest: "¿En qué momentos del día comes, desayunas y cenas?",
        order: 6,
        clout: -0.5,
        cloutLimits: [0, 0.3],
        options: [
            { id: 1, text: "Desayuno" },
            { id: 2, text: "Almuerzo" },
            { id: 3, text: "Cena" }
        ],
        type: typeQeuestionsSaturation.timeRangeOptionsField
    },
    {
        id: 7,
        quest: "¿En qué momentos del día descanso, y en promedio cuanto tiempo (fuera de los períodos de alimentación)?",
        order: 7,
        clout: -0.5,
        cloutLimits: [0, 0.3],
        type: typeQeuestionsSaturation.timeRangeTodo
    },
    {
        id: 8,
        clout: -0.5,
        cloutLimits: [0, 0],
        quest: "¿En qué momentos del día me distraigo más?",
        order: 8,
        type: typeQeuestionsSaturation.timeRangeTodo
    }

]