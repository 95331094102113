// export const HOST_APP = "http://localhost:7070/";
// export const API_SERVER = process.env.HOST_API ? process.env.HOST_API : "http://localhost:3000";
export const BASE_ROUTE = "";

// export const HOST_APP = "http://personastest.bioticasostenible.com/";
export const HOST_APP = "http://personas.bioticasostenible.com/";
export const API_SERVER = "http://138.68.41.196:3000";
// export const BASE_ROUTE = "biotica_personas";

//**UTILS */
export const OPEN_MODAL_REDUCE = "OPEN_MODAL_REDUCE";
export const CLOSE_MODAL_REDUCE = "CLOSE_MODAL_REDUCE";
//**END UTILS */



export const LOGIN_SERVICE = `${API_SERVER}/p_personas/login`;
export const LOGIN_REDUCE = "LOGIN_REDUCE";
export const LOGIN_SAGA = "LOGIN_SAGA";

export const SET_ACTOR_REDUCE = "SET_ACTOR_REDUCE";

export const GET_QUESTIONS_SERVICE = `${API_SERVER}/p_personas/getQuestios`;
export const SET_QUESTIONS_SAGA = "SET_QUESTIONS_SAGA";
export const SET_QUESTIONS_REDUCE = "SET_QUESTIONS_REDUCE";


export const SAVE_ANSWERS_SERVICE = `${API_SERVER}/p_personas/setAnswers`;
export const SAVE_ANSWERS_SAGA = "SAVE_ANSWERS_SAGA";
export const SET_ANSWERS_REDUCE = "SET_ANSWERS_REDUCE";

export const SAVE_INTEREST_ACTOR_SERVICE = `${API_SERVER}/p_personas/setInterstActor`;
export const SAVE_INTEREST_ACTOR_SAGA = "SAVE_INTEREST_ACTOR_SAGA";
export const SET_INTEREST_ACTOR_REDUCE = "SET_INTEREST_ACTOR_REDUCE";

export const GET_INTEREST_ACTOR_SERVICE = `${API_SERVER}/p_personas/getInterstActor`;
export const GET_INTEREST_ACTOR_SAGA = "GET_INTEREST_ACTOR_SAGA";

export const GET_ANSWERS_SERVICE = `${API_SERVER}/p_personas/getActorAnswers`;
export const GET_ANSWERS_SAGA = 'GET_ANSWERS_SAGA';

export const GET_ANHELOS_SERVICES = `${API_SERVER}/p_personas/getAnhelos`;
export const GET_ANHELOS_REDUCE = "GET_ANHELOS_REDUCE";
export const GET_ANHELOS_SAGA = "GET_ANHELOS_SAGA";

export const GET_ALL_ANSWERS_SERVICE = `${API_SERVER}/p_personas/getAllAnswers`;
export const GET_ALL_ANSWERS_SAGA = `GET_ALL_ANSWERS_SAGA`;
export const SET_ALL_ANSWERS_REDUCER = `SET_ALL_ANSWERS_REDUCER`;


export const GET_ANHELOS_BENEFICIOS_SERVICE = `${API_SERVER}/p_personas/getAnhelosBeneficios`;
export const GET_ANHELOS_BENEFICIOS_SAGA = `GET_ANHELOS_BENEFICIOS_SAGA`;
export const SET_ANHELOS_BENEFICIOS_REDUCER = `SET_ANHELOS_BENEFICIOS_REDUCER`;


export const GET_ALL_ENEA_QUESTIOS_SERVICE = `${API_SERVER}/p_personas/getAllEneaQuestions`;
export const GET_ALL_ENEA_QUESTIOS_SAGA = `GET_ALL_ENEA_QUESTIOS_SAGA`;
export const SET_ALL_ENEA_QUESTIOS_REDUCER = `SET_ALL_ENEA_QUESTIOS_REDUCER`;


export const SET_ENEA_ACTOR_SERVICE = `${API_SERVER}/p_personas/setEneatipoActor`;
export const SET_ENEA_ACTOR_SAGA = `SET_ENEA_ACTOR_SAGA`;


export const SET_ASERT_ACTOR_SERVICE = `${API_SERVER}/p_personas/setAcertActor`;
export const SET_ASERT_ACTOR_SAGA = `SET_ASERT_ACTOR_SAGA`;

export const GET_ACTOR_SAGA = `GET_ACTOR_SAGA`;


export const GET_ALL_ENEA_BENEFICIOS_SERVICE = `${API_SERVER}/p_personas/getAllEneaBeneficios`;
export const GET_ALL_ENEA_BENEFICIOS_SAGA = `GET_ALL_ENEA_BENEFICIOS_SAGA`;
export const SET_ENEA_BENEFICIOS_REDUCER = `SET_ENEA_BENEFICIOS_REDUCER`;

export const UPDATE_INIT_INFO_ACTOR_SERVICE = `${API_SERVER}/p_personas/setInitInfoActor`;
export const UPDATE_INIT_INFO_ACTOR_SAGA = `UPDATE_INIT_INFO_ACTOR_SAGA`;

export const GET_ALL_COUNT_INTERSTEDS_ACTORS_SERVICE = `${API_SERVER}/p_personas/getAllInterstsCountActor`;
export const SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA = `SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA`;
export const SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER = `SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER`;


export const GET_ALL_INTERSTEDS_ACTORS_SERVICE = `${API_SERVER}/p_personas/getAllActorsInterests`;
export const SET_ALL_INTERSTEDS_ACTORS_SAGA = `SET_ALL_INTERSTEDS_ACTORS_SAGA`;
export const SET_ALL_INTERSTEDS_ACTORS_REDUCER = `SET_ALL_INTERSTEDS_ACTORS_REDUCER`;

export const GET_CONTEXT_FEEDBACK_SERVICE = `${API_SERVER}/p_personas/getFeedbackContextByTeam`;
export const GET_CONTEXT_FEEDBACK_SAGA = `GET_CONTEXT_FEEDBACK_SAGA`;
export const SET_CONTEXT_FEEDBACK_REDUCER = `SET_CONTEXT_FEEDBACK_REDUCER`;


export const GET_FRECUENT_FEEDBACK_CATEGORIES_SERVICE = `${API_SERVER}/p_personas/getFrecuentFeedbackCategories`;
export const GET_FRECUENT_FEEDBACK_CATEGORIES_SAGA = `GET_FRECUENT_FEEDBACK_CATEGORIES_SAGA`;
export const GET_FRECUENT_FEEDBACK_CATEGORIES_REDUCER = `GET_FRECUENT_FEEDBACK_CATEGORIES_REDUCER`;

export const SET_FEEDBACK_CONTEXT_ACTOR_SERVICE = `${API_SERVER}/p_personas/setFeedbackContextActor`;
export const SET_FEEDBACK_CONTEXT_ACTOR_SAGA = `SET_FEEDBACK_CONTEXT_ACTOR_SAGA`;



export const GET_SATURATION_POINT_NOTIFICATION_SERVICE = `${API_SERVER}/p_personas/getSaturationPointNotification`;
export const GET_SATURATION_POINT_NOTIFICATION_SAGA = `GET_SATURATION_POINT_NOTIFICATION_SAGA`;
export const SET_SATURATION_POINT_NOTIFICATION_REDUCER = `SET_SATURATION_POINT_NOTIFICATION_REDUCER`;

export const PUT_SATURATION_POINT_NOTIFICATION_SERVICE = `${API_SERVER}/p_personas/putSaturationPointNotification`;
export const PUT_SATURATION_POINT_NOTIFICATION_SAGA = `PUT_SATURATION_POINT_NOTIFICATION_SAGA`;




export const SET_CURRENT_CONTEXT_CATEGORY_REDUCER = `SET_CURRENT_CONTEXT_CATEGORY_REDUCER`;
export const UNSET_CURRENT_CONTEXT_CATEGORY_REDUCER = `UNSET_CURRENT_CONTEXT_CATEGORY_REDUCER`;


export const SET_ALL_ACTORS_COMPANY_REDUCER = `SET_ALL_ACTORS_COMPANY_REDUCER`;

export const UPDATE_FOOTER_REDUCER = `UPDATE_FOOTER_REDUCER`;
export const UPDATE_FOOTER_VALUE_REDUCER = `UPDATE_FOOTER_VALUE_REDUCER`;
export const ENABLE_FOOTER_NEXT_REDUCER = `ENABLE_FOOTER_NEXT_REDUCER`;
export const DISABLE_FOOTER_NEXT_REDUCER = `DISABLE_FOOTER_NEXT_REDUCER`;

export const SET_ALERT = `SET_ALERT`;
export const UNSET_ALERT = `UNSET_ALERT`;

export const GET_CONFIG_SERVICE = `config/`;
export const GET_CONFIG_SAGA = 'GET_CONFIG_SAGA';
export const SET_CONFIG_REDUCER = 'SET_CONFIG_REDUCER';


export const SET_DEFINITION_ITEM = `SET_DEFINITION_ITEM`;
export const UNSET_DEFINITION_ITEM = `UNSET_DEFINITION_ITEM`;

export const GET_TOOLS_SERVICE = 'tools/';
export const GET_TOOLS_SAGA = 'GET_TOOLS_SAGA';
export const SET_TOOLS_REDUCER = 'SET_TOOLS_REDUCER';

export const USER_TOOLS_SERVICE = 'users/';

export const PUT_NEW_PROYECT_SAGA = 'PUT_NEW_PROYECT_SAGA';
export const PUT_NEW_TASKS_SAGA = 'PUT_NEW_TASKS_SAGA';
export const DELETE_PROYECT_SAGA = 'DELETE_PROYECT_SAGA';
export const DELETE_TASK_SAGA = 'DELETE_TASK_SAGA';

export const PUT_TIME_BREATHING_SAGA = 'PUT_TIME_BREATHING_SAGA';
export const SET_TIME_BREATHING_SAGA = 'SET_TIME_BREATHING_SAGA';

export const SET_USER_TOOLS_STATE_SAGA = 'SET_USER_TOOLS_STATE_SAGA';

export const INIT_WATCH_TOOLS_SAGA = 'INIT_WATCH_TOOLS_SAGA';






export const questionsDesatend = {
    "genero": {
        title: "Escoge tu genero:",
        options: [
            { name: "Mujer", img: "/genere/female.png", value: "M" },
            { name: "Hombre", img: "/genere/male.png", value: "H" }
        ]
    },
    "fingerPrint": {
        options: [
            { img: "/fingerprint/1.png", value: "1" },
            { img: "/fingerprint/2.png", value: "2" },
            { img: "/fingerprint/3.png", value: "3" }
        ]
    },
    "avatar": {
        title: "Elige un avatar:",
        options: [
            { img: "/avatares/man_1.png", value: "1" },
            { img: "/avatares/women_2.png", value: "2" },
            { img: "/avatares/man_2.png", value: "3" },
            { img: "/avatares/women_1.png", value: "4" }
        ]
    },
    "fears": {
        title: "Cual de los siguientes enunciados te genera mas temor."
    }
}


export const avatares = {
    "1": "/avatares/man_1.png",
    "2": "/avatares/women_2.png",
    "3": "/avatares/man_2.png",
    "4": "/avatares/women_1.png"
}

export const fingerPrint = {
    "1": "Mental",
    "2": "Emocioanl",
    "3": "Racional"
}






export const habilidadesEsenciales = [
    { habilidad: "Comunicación", key: "comunicacion" },
    { habilidad: "liderazgo", key: "liderazgo" },
    { habilidad: "Capacidad de aprendizaje", key: "capacidadAprendizage" },
    { habilidad: "Trabajo en equipo", key: "trabajoEquipo" }
];

export const anhelosdef = {
    "Personal": {
        anhelonumber: 1,
        anhelo: "Personal",
        descripcion: "Eres una persona en búsqueda de tu anhelo personal, te interesa desarrollar tu potencial, tienes un alto sentido de identidad y autoestima. Te gusta encontrar el sentido de todo y liderar, al igual que asociar tu pasión por todo lo que haces. Tu temor es no cumplir sus anhelos actuales y pasados.",
        descripcionParcial: "Le interesa desarrollar su potencial, tiene un alto sentido de identidad y autoestima. Le gusta encontrar el sentido de todo y liderar, al igual que asociar su pasión por todo lo que haces. su temor es no cumplir sus anhelos actuales y pasados.",
        centro: "Superación"
    },
    "Social": {
        anhelonumber: 2,
        anhelo: "Social",
        descripcion: "Tú te sientes cómodo con el poder y estar rodeado de muchas personas, aunque en ocasiones necesitas la aprobación de los demás. Está siempre en una búsqueda de estatus, respeto y fama consciente o inconsciente. Estás muy enfocado en el hacer. Tu temor se activa cuando siente des-aprobación, vergüenza o juicio.",
        descripcionParcial: "Se siente cómodo con el poder y estar rodeado de muchas personas, aunque en ocasiones necesita la aprobación de los demás. Está siempre en una búsqueda de estatus, respeto y fama consciente o inconsciente. Esta muy enfocado en el hacer. su temor se activa cuando siente des-aprobación, vergüenza o juicio.",
        centro: "Relaciones"
    },
    "Life Style": {
        anhelonumber: 3,
        anhelo: "Life Style",
        descripcion: "A ti te gusta sentirte siempre en libertad, lejos de espacios o entornos estructurados y poco flexibles y puede que te cueste el compromiso. Uno de tus centros es la búsqueda del cambio constante y dinámico. Te gusta disfrutar de los placeres de la vida y valoras las experiencias y compañías.  Tu mayor temor es el conflicto entre lo que quieres y cómo lo consigues.",
        descripcionParcial: "Le gusta sentirse siempre en libertad, lejos de espacios o entornos estructurados y poco flexibles y puede que le cueste el compromiso. Uno de sus centros es la búsqueda del cambio constante y dinámico. Le gusta disfrutar de los placeres de la vida y valora las experiencias y compañías.  su mayor temor es el conflicto entre lo que quieres y cómo lo consigues.",
        centro: "Libertad"
    },
    "Moral": {
        anhelonumber: 4,
        anhelo: "Moral",
        descripcion: "Tu anhelo está dado por el servicio a los demás y cómo puede tener impacto en su entorno, de esta manera buscas aumentar tu satisfacción. La sostenibilidad y pensar en equipo es importantes para ti. Te puedes conectar con los dolores de los demás y en ocasiones, sin darte cuenta, que puedes llegar a sacrificar tu bienestar (salud, tiempo, relaciones, etc). Tu temor es sentir no tiene el poder para marcar la diferencia.",
        descripcionParcial: "Está dado por el servicio a los demás y cómo puede tener impacto en su entorno, de esta manera busca aumentar su satisfacción. La sostenibilidad y pensar en equipo es importantes para él. se puede conectar con los dolores de los demás, aunque en ocasiones se puede descubrir sacrificando su bienestar (salud, tiempo, relaciones, etc). Su temor es sentir no tiene el poder para marcar la diferencia.",
        centro: "Filantropía"
    },
    "Practical": {
        anhelonumber: 5,
        anhelo: "Practical",
        descripcion: "Como lo dice tu anhelo es ser práctico y viajar ligero de equipaje. Puedes tener lo mínimo necesario y  este pensamiento te llena de objetividad al tomar decisiones de cualquier tipo (Dinero, Tiempo, Esfuerzo). Eres muy bueno para estructurar y solucionar problemas. Intentas controlar todos los aspectos de tu vida para mantener una zona de confort. Tu temor está dado por el miedo a no tener lo esencial.",
        descripcionParcial: "Es práctico y le gusta viajar ligero de equipaje. Puede tener lo mínimo necesario y  este pensamiento le llena de objetividad al tomar decisiones de cualquier tipo (Dinero, Tiempo, Esfuerzo). Es muy bueno para estructurar y solucionar problemas. Intenta controlar todos los aspectos de su vida para mantener una zona de confort. Su temor está dado por el miedo a no tener lo esencial.",
        centro: "Análisis"
    }
}




const mapWeight = {
    "45": { id: 45, weight: 30, descripcion: "Compromiso" },
    "54": { id: 54, weight: 20, descripcion: "Relacion con el entorno" },
    "55": { id: 55, weight: 20, descripcion: "Comunicacion" },
    "56": { id: 56, weight: 30, descripcion: "Desarrollo del potencial" }
}

const enumEstadosCalificacion = {
    "poco": 3,
    "mucho": 5,
    "nada": 0
}

export const enumPrguntas = {
    haceCuanto: 47,
    hastaCuando: 48,
    conQueFrecuencua: 84
}

export const enumScheduleType = {
    JOB: "JOB",
    // HOME: "HOME",
    BREACK: "BREACK",
    HOBBIES: "HOBBIES",
}

export const enumScheduleTypeText = {
    JOB: "Laboral",
    // HOME: "Personal",
    BREACK: "Pausa activa",
    HOBBIES: "Hobbies",
}

export const optionsTaskModal = {
    BREACK: ["Respirar", "Comer", "Hablar con alguien", "Llamada", "Otro" ],
    HOBBIES: ["Deporte", "Leer", "Jardineria", "Cocinar", "Otro" ]
}

// export const enumFeedbackContext = {
//     fdbkExt: {
//         id: 1,
//         category: {
//             feedback: 1
//         }
//     },
//     fdbkMine: {
//         id: 6,
//         category: {
//             init: 19,
//             group: [19, 20, 21, 22, 23, 24, 25, 26]
//         }
//     },
//     breathing: {
//         id: 1,
//         category: {
//             feedback: 5
//         }
//     }

// }

export const enumFeedbackContext = {
    fdbkExt: {
        id: 5,
        category: {
            feedback: 17
        }
    },
    fdbkMine: {
        id: 6,
        category: {
            init: 19,
            group: [19, 20, 21, 22, 23, 24, 25, 26]
        }
    },
    breathing: {
        id: 7,
        category: {
            feedback: 18
        }
    }

}


export const questiosGroupByActor = (questions) => {
    return questions.filter(question => !!question.respuesta)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {})
}

export const groupActorQuestiosById = (actorQuestions) => {
    return Object.keys(actorQuestions).reduce((a, b) => {
        a[b] = actorQuestions[b]
            .reduce((c, d) => {
                c[d.idPregunta] = d;
                return c;
            }, {})
        return a;
    }, {})
}

export const getValue = (obj, def, keys) => {
    let valor = def;
    [].concat(keys).forEach((item, index) => {
        valor = index == 0 ?
            !!obj[item] ? obj[item] : def :
            !!valor[item] ? valor[item] : def;
    })
    return valor;
}


export const buildSegmentByTime = (questions) => {

    let promGlobal = {};
    let prom = [];

    let sinceMax = Object.keys(questions).reduce((a, b) => {
        let since = Number(getValue(questions, 0, [b, enumPrguntas.hastaCuando, "respuesta"]));
        return since > a ? since : a;
    }, 0)

    let sinceBreack = (sinceMax) / 4;

    Object.keys(questions).forEach(_actorId => {
        let _anios = getValue(questions, 0, [_actorId, enumPrguntas.hastaCuando, "respuesta"]);
        let _date = getValue(questions, (new Date()).toString(), [_actorId, enumPrguntas.hastaCuando, "fechaCreacion"]);
        let since = Number(getValue(questions, 0, [_actorId, enumPrguntas.haceCuanto, "respuesta"]));

        let until = new Date(_date);
        until.setFullYear(until.getFullYear() + parseInt(_anios))
        let untilYear = until.getFullYear();

        let section = Math.trunc(since / sinceBreack);

        if (_anios > 0) {
            promGlobal[untilYear] = promGlobal[untilYear] ? promGlobal[untilYear] + 1 : 1;
            prom.push({ untilYear, section, until: Number(_anios), since })
        }
    })

    let labelsArray = {};
    let promBySections = {};
    let promBySectionsCount = {};
    let groups = prom.reduce((a, b, i, obj) => {
        let _sinceBreack = Math.trunc(sinceBreack);
        let labelSection = `${_sinceBreack * b.section}-${(_sinceBreack * b.section) + _sinceBreack}`;
        labelsArray[labelSection] = 0;
        a[labelSection] = Object.assign({}, a[labelSection]);
        a[labelSection][b.untilYear] = a[labelSection][b.untilYear] ? a[labelSection][b.untilYear] + 1 : 1;
        promBySections[labelSection] = getValue(promBySections, 0, labelSection) + (b.until + b.since);
        promBySectionsCount[labelSection] = getValue(promBySectionsCount, 0, labelSection) + 1;

        return a;
    }, {})


    let _groupsPersonas = Object.keys(groups).reduce((a, b, i, obj) => {
        Object.keys(groups[b]).forEach((anio) => {
            a[anio] = Object.assign({}, a[anio]);
            a[anio][b] = groups[b][anio];
        })
        return a;
    }, {})


    return {
        labels: Object.keys(labelsArray),
        data: Object.keys(promGlobal).map(_anio => {
            let resp = { anio: _anio, personas: promGlobal[_anio], ...labelsArray }
            return Object.assign(resp, _groupsPersonas[_anio]);
        }),
        prom: Object.keys(promBySections).map(label => {
            return {
                section: label,
                prom: Number(promBySections[label] / promBySectionsCount[label]).toFixed(1)
            }
        })
    };
}


export const buildSegment = (questions, field) => {

    let promGlobal = {};
    let prom = [];


    Object.keys(questions).forEach(_actorId => {
        let _anios = getValue(questions, 0, [_actorId, enumPrguntas.hastaCuando, "respuesta"]);
        let _date = getValue(questions, (new Date()).toString(), [_actorId, enumPrguntas.hastaCuando, "fechaCreacion"]);
        let since = Number(getValue(questions, 0, [_actorId, enumPrguntas.haceCuanto, "respuesta"]));

        let _actor = getValue(questions, {}, [_actorId, enumPrguntas.hastaCuando, "p_actor"]);

        let until = new Date(_date);
        until.setFullYear(until.getFullYear() + parseInt(_anios))
        let untilYear = until.getFullYear();

        //console.log("_actor", _actor);

        let section = _actor[field];

        if (_anios > 0) {
            promGlobal[untilYear] = promGlobal[untilYear] ? promGlobal[untilYear] + 1 : 1;
            prom.push({ untilYear, section, until: Number(_anios), since })
        }
    })

    let labelsArray = {};
    let promBySections = {};
    let promBySectionsCount = {};
    let groups = prom.reduce((a, b, i, obj) => {
        let labelSection = b.section;
        labelsArray[labelSection] = 0;
        a[labelSection] = Object.assign({}, a[labelSection]);
        a[labelSection][b.untilYear] = a[labelSection][b.untilYear] ? a[labelSection][b.untilYear] + 1 : 1;
        promBySections[labelSection] = getValue(promBySections, 0, labelSection) + (b.until + b.since);
        promBySectionsCount[labelSection] = getValue(promBySectionsCount, 0, labelSection) + 1;

        return a;
    }, {})


    let _groupsPersonas = Object.keys(groups).reduce((a, b, i, obj) => {
        Object.keys(groups[b]).forEach((anio) => {
            a[anio] = Object.assign({}, a[anio]);
            a[anio][b] = groups[b][anio];
        })
        return a;
    }, {})


    return {
        labels: Object.keys(labelsArray),
        data: Object.keys(promGlobal).map(_anio => {
            let resp = { anio: _anio, personas: promGlobal[_anio], ...labelsArray }
            return Object.assign(resp, _groupsPersonas[_anio]);
        }),
        prom: Object.keys(promBySections).map(label => {
            return {
                section: label,
                prom: Number(promBySections[label] / promBySectionsCount[label]).toFixed(1)
            }
        })
    };
}



const PormedioRotacion = (answersCompany, answersCollaboratos) => {

    let dataCompany = answersCompany.find(item => item.idPregunta == 84);


    let toDictionary = answersCollaboratos
        .filter(item => !!item.respuesta)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {})


    let dataCollaborators = [];

    Object.keys(toDictionary).forEach(actor => {
        let tiempoAc = toDictionary[actor].find(item => item.idPregunta == 47);
        let tiempoEst = toDictionary[actor].find(item => item.idPregunta == 48);

        let _tiempoAc = parseFloat(tiempoAc ? tiempoAc.respuesta : 0);
        let _tiempoEst = parseFloat(tiempoEst ? tiempoEst.respuesta : 0);

        dataCollaborators.push({
            name: `${_tiempoAc + _tiempoEst} Años`,
            value: _tiempoAc + _tiempoEst
        })
    })

    let promedio = dataCollaborators.map(item => item.value).reduce((a, b) => { return a + b; }, 0)


    return {
        comapnyValue: dataCompany ? dataCompany.respuesta : null,
        collaboratosData: dataCollaborators,
        promedio: Number(promedio / dataCollaborators.length).toFixed(1)
    }
}

const GetSatisfaccionPromedio = (answersCompany, answersCollaboratos) => {

    let ids = Object.keys(mapWeight).map((item) => {
        return mapWeight[item].id
    })

    let toDictionary = answersCollaboratos
        .filter(item => ids.indexOf(item.idPregunta) > -1)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {})

    let totalSatisfaccion = 0;
    let arrayTotalSatisfaccion = [];
    let discriminacionAspecto = [];

    Object.keys(toDictionary).forEach(actor => {
        let nivelSatisfaccion = 0;

        toDictionary[actor].forEach((respuesta) => {
            let _weight = mapWeight[respuesta.idPregunta].weight;
            let resp = parseInt(respuesta.respuesta);
            //5 = el numero en slider
            let _nivelSatisfaccion = _weight * (resp * 100 / 5) / 100;
            nivelSatisfaccion += _nivelSatisfaccion;

            discriminacionAspecto.push({
                nivelSatisfaccion: _nivelSatisfaccion,
                respuesta: (resp * 100 / 5),
                idPregunta: respuesta.idPregunta
            })
        });
        totalSatisfaccion += nivelSatisfaccion;
        arrayTotalSatisfaccion.push(nivelSatisfaccion);
    });

    let _discriminacionAspecto = discriminacionAspecto.reduce((a, b) => {
        a[b.idPregunta] = a[b.idPregunta] ? a[b.idPregunta] : 0;
        a[b.idPregunta] = a[b.idPregunta] + b.respuesta;
        return a;
    }, {})

    //console.log(arrayTotalSatisfaccion, totalSatisfaccion, Object.keys(toDictionary).length);

    return {
        satisfaccion: Number(totalSatisfaccion / Object.keys(toDictionary).length).toFixed(1), // promedio de satisfaccion
        aspectos: Object.keys(mapWeight).map(item => {
            return {
                //prom: (mapWeight[item].weight * (_discriminacionAspecto[item] / Object.keys(toDictionary).length)) / 100,
                prom: Number(_discriminacionAspecto[item] / Object.keys(toDictionary).length).toFixed(1),
                peso: mapWeight[item].weight,
                descripcion: mapWeight[item].descripcion
            }
        })
    }
}

const BuildCrecimiento = (answersCompany, answersCollaboratos) => {

    let toDictionary_profesional = Object.assign([], answersCollaboratos)
        .filter(item => [50, 51].indexOf(item.idPregunta) > -1)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {});

    let toDictionary_personal = Object.assign([], answersCollaboratos)
        .filter(item => item.idPregunta == 52).reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {});



    let _crecimientoProfesional = [];
    Object.keys(toDictionary_profesional).forEach(actor => {
        let crecimientoProf1 = toDictionary_profesional[actor].find(item => item.idPregunta == 50);
        let crecimientoProf2 = toDictionary_profesional[actor].find(item => item.idPregunta == 51);

        let _weight = enumEstadosCalificacion[crecimientoProf2.p_respuesta.respuesta];
        let _weight2 = enumEstadosCalificacion[crecimientoProf1.p_respuesta.respuesta];

        let finalWeight = (_weight + _weight2) / 2;

        let califi = "Mucho";

        if (finalWeight < 3) {
            let califi = "Poco";
        } else if (finalWeight < 1) {
            let califi = "Nada";
        }

        _crecimientoProfesional.push({
            name: califi,
            value: finalWeight
        })
    })
    let _crecimientoPersona = [];
    Object.keys(toDictionary_personal).forEach(actor => {

        let crecimientoPers = toDictionary_personal[actor].pop();

        let _weight = enumEstadosCalificacion[crecimientoPers.p_respuesta.respuesta];
        let califi = "Mucho";
        switch (crecimientoPers.p_respuesta.respuesta) {
            case 'poco':
                califi = "Poco";
                break;
            case 'nada':
                califi = "Nada";
                break;
            case 'mucho':
                califi = "Mucho";
                break;
        }

        _crecimientoPersona.push({
            name: califi,
            value: _weight
        })
    })

    // toDictionary_personal

    return {
        creciPersona: _crecimientoPersona,
        creciPofesional: _crecimientoProfesional
    }
}


export const buildActions = {
    "rotacion": PormedioRotacion,
    "satisfaccion": GetSatisfaccionPromedio,
    "crecimiento": BuildCrecimiento
};

const metrics_satisfaction = {
    "rotacion": {
        title: "Rotacion - Ciclo de vida"
    },
    "satisfaccion": {
        title: "Satisfacción"
    },
    "crecimiento": {
        title: "Crecimiento"
    },
};

export const buildMetriscs = (allAnswers, actor) => {

    let answersCompany = Object.assign([], allAnswers.filter(item => item.idActor == actor.id));
    let answersCollaboratos = Object.assign([], allAnswers.filter(item => item.idActor != actor.id));

    let results = {};

    Object.keys(metrics_satisfaction).map((_metric) => {
        results[_metric] = {
            nombre: _metric,
            valores: buildActions[_metric](answersCompany, answersCollaboratos)
        }
    })
    return results;
}


export const accionesEnea = {
    "1": [
        "Realiza actividades de forma creativas, sal de las formas y estructuras para lo que no necesitas.",
        "Comunica tus ideas y sentimientos son súper valiosas para la gente que te rodea.",
        "Hazte consciente de tus pensamientos cuando estás creando juicios de los otros. Para y valora la individualidad que existe y lo que nos aporta la diversidad."
    ],
    "4": [
        "Deja fluir tus sentimientos y habla con alguien de confianza y sin cohibirte. Te darás cuenta que muchas personas pasan por situaciones similares a las tuyas.",
        "Observa la gente que te rodea, su originalidad y como todos somos diferentes a nuestra propia manera.",
        "Tú eres muy creativo, comparte tus ideas en la solución de problema que nos permiten pensar fuera de nuestra zona de confort."
    ],
    "9": [
        "Estar. Este es tu reto, compartir tu tiempo y tus opiniones con las personas que tienes alrededor.",
        "Toma decisiones sin miedo. Estamos aquí por una razón y es vivir.",
        "Define dos temas en los que vas a establecer rutinas productivas para lograr las primeras metas que te propongas. Y vamos a cumplirlas!!!"
    ],
    "3": [
        "Haz escuchado: “solo se llega más rápido, en equipo se llega más lejos”. Este es tu reto: trabaja en equipo.",
        "La vida es más allá de una imagen, una apariencia o expectativa por cumplir. Conéctate!!! Contigo, con las experiencias, con la gente tal como son.",
        "Cuál es tu hobbie? Cuanto tiempo de calidad pasas con la gente que quieres? Define un par de espacios esta semana para ti, donde compartos con lo que más te guste."
    ],
    "8": [
        "Pon tu mano derecha en el corazón y siente. El latir, como sale sangre a todo el cuerpo y las sensaciones que se generan. Se consciente de tí. Este puede ser un primer paso para conectarte mas contigo y tus sentimientos.",
        "Respira!!!  Antes de reaccionar impulsivamente cuenta hasta 10.",
        "Puedes imaginar cómo sería un mundo donde no tengamos que protegernos? Atrévete, deja tantas prevenciones y abre el caparazón en el que a ratos te refugias. Las sorpresas de la vida te están esperando."
    ],
    "7": [
        "Cuando te invada la impaciencia haz una lista de lo que tengas que hacer, prioriza y empieza a actuar. Luego plantéate si se justifica que tu energía se lo lleve el afán o mejor lo usas en la acción.",
        "Ya sabemos que eres muy bueno planeando. Ahora hay que llevarlo al hacer. Se consciente del aquí y el ahora y úsalo para avanzar.",
        "Escucha atentamente, aunque sepas cómo va a terminar la historia de tu interlocutor. ¿Cuántos días crees que puedes lograr sin completar las palabras o frases de las personas con las que hablas?"
    ],
    "2": [
        "Cuando ayudas pregúntate qué te mueve a hacerlo? Si esperas algo a cambio? Reconocimiento o aprobación? O cuando ayudas por el placer de ayudar.",
        "Amate a ti mismo. Saca un espacio para consentirte, leer o estar un rato contigo mismo y recargar energías.",
        "Define un tema que tenga pendiente hace rato y anímate a pedir ayuda. Dar y Recibir siempre buscan ser un equilibrio."
    ],
    "6": [
        "Confía en tu propio criterio, nadie sabe mejor que tú lo que debes hacer. Puedes hacer listados de pro y contra, imaginarte en distintas situaciones y reconocer cómo te sentirías en ellas o confiar en tu voz interior.",
        "Concéntrate en el presente. Las proyecciones son buenas para prepararnos o alinear acciones, sin embargo cuando te quedas viviendo en ellas, te pierdes de la vida.",
        "Cuántas veces dices “no sé” al día? Aun sabiendo la respuesta. Te reto, eres capaz de eliminar esta respuesta automática de ti? Toma conciencia, reconoce lo que si sabes y confía en tu intuición."
    ],
    "5": [
        "Practica un deporte. Conéctate más con tu cuerpo, salte un poco de tu mundo de ideas. Por este mes, saca dos horas semanales y define qué actividad física va a practicar. Recuerda que para trotar solo necesitas tenis y salir a la calle o para hacer yoga un tapete y poner play a una clase en youtube.",
        "Deja fluir tus sentimientos y habla con alguien de confianza y sin cohibirte. Te darás cuenta que muchas personas pasan por situaciones similares a las tuyas y que los sentimientos nos permiten escuchar nuestra propia voz.",
        "Permítete explorar, vive el conocimiento. Recuerda que la sabiduría es la suma del conocimiento y la experiencia. Te reto a que lleves a la acción un tema que llame tu atención."
    ]
}


export const anhelosEnum = {
    1: "Personal",
    2: "Social",
    3: "Life Style",
    4: "Moral",
    5: "Practical"
}

export const buildPercentAnhelo = (allAnswers, anhelosBeneficios, actor, anhelos) => {

    let answersCollaboratos = Object.assign([], allAnswers.filter(item => item.idActor != actor.id));

    let toDictionary = Object.assign([], answersCollaboratos)
        .reduce((a, b) => {
            a[b.idActor] = Object.assign([], a[b.idActor]);
            a[b.idActor].push(b);
            return a;
        }, {});



    let consolidate = {};
    let _consolidate = [];
    let anheloActors = {};

    Object.keys(toDictionary).map(actor => {
        let anh = getAnheloPersona(toDictionary[actor], anhelos);
        _consolidate.push(anh.consolidado);
        consolidate[anh.predominante.anhelo] = consolidate[anh.predominante.anhelo] ? consolidate[anh.predominante.anhelo] + 1 : 1;
        anheloActors[anh.predominante.anhelo] = Object.assign([], anheloActors[anh.predominante.anhelo]);
        anheloActors[anh.predominante.anhelo].push(actor);
    })

    let _consolidateUnified = {};
    _consolidate.forEach(consol => {
        consol.forEach(_consol => {
            _consolidateUnified[_consol.anhelo] = _consolidateUnified[_consol.anhelo] != undefined ?
                _consolidateUnified[_consol.anhelo] + _consol.number : _consol.number;
        })
    })


    let _total = Object.keys(consolidate).filter(item => !!item).map(m => consolidate[m]).reduce((a, b) => a + b, 0);
    let _totalUnified = Object.keys(_consolidateUnified).map(m => _consolidateUnified[m]).reduce((a, b) => a + b, 0);

    let _anhelosBeneficios = Object.assign([], anhelosBeneficios).reduce((a, b) => {
        a[b.anhelonumber] = Object.assign([], a[b.anhelonumber]);
        a[b.anhelonumber].push(b);
        return a;
    }, {})


    return {
        consolidate: Object.keys(consolidate).filter(item => !!item).map(item => {
            return {
                anheloNombre: item,
                porcentaje: Number(consolidate[item] * 100 / _total).toFixed(1),
                aspectos: _anhelosBeneficios[anhelosdef[item].anhelonumber],
                actors: anheloActors[item]
            }
        }),
        consolidateUnified: Object.keys(_consolidateUnified).map(item => {
            return {
                name: item,
                value: _consolidateUnified[item],
                anheloNombre: item,
                porcentaje: Number(_consolidateUnified[item] * 100 / _totalUnified).toFixed(1),
                aspectos: _anhelosBeneficios[anhelosdef[item].anhelonumber]
            }
        })
    };

}

export const getAnheloPersona = (answers, anhelos) => {

    let consolidate = {};
    answers.filter(answ => answ.idRespuesta != null).forEach(answ => {
        let _anhelos = anhelos.find(anh => anh.idRespuesta == answ.idRespuesta);
        if (_anhelos) {
            [].concat(_anhelos).forEach(anh => {
                consolidate[anh.anhelo] = consolidate[anh.anhelo] ? consolidate[anh.anhelo] + 1 : 1;
            });
        }
    });

    let simplify = Object.keys(consolidate)
        .map(item => ({ "anhelo": item, "number": consolidate[item] }))

    let predominante = simplify.reduce((a, b, i) => {
        return a.number < b.number ? b : a
    }, { number: -1 })

    return {
        consolidado: simplify,
        predominante
    };
}

export const buildImpactInterst = (interest, interestSeed, anhelos, actors) => {

    let anheloPorcentaje = anhelos.consolidate.reduce((a, b) => {
        a[b.anheloNombre] = b.porcentaje
        return a;
    }, {})

    let actorsAnhelo = anhelos.consolidate.reduce((a, b) => {
        let actorsDictionariAnhelo = b.actors.reduce((_a, _b) => {
            _a[_b] = b.anheloNombre
            return _a;
        }, {})
        return { ...a, ...actorsDictionariAnhelo };
    }, {})


    let toActorDictionary = Object.assign([], interest)
        .reduce((a, b) => {
            a[b.idActor] = { ...b.p_actor, anhelo: actorsAnhelo[b.idActor] };
            return a;
        }, {});


    let groupInterestParent = interest
        .filter(interest => (actors == undefined || actors.indexOf(String(interest.idActor)) > -1))
        .reduce((a, b) => {
            let segmente = interestSeed[b.route.split(",").shift()];

            a[segmente.item] = Object.assign([], a[segmente.item]);
            a[segmente.item].push(b);
            return a;
            //a[b.route]
        }, [])


    return Object.keys(groupInterestParent).map(parent => {
        return {
            parent,
            interests: groupInterestParent[parent].reduce((a, b) => {
                a[b.interest] = Object.assign([], a[b.interest]);
                a[b.interest].push(b.idActor)
                return a;
            }, {})
        }
    }).map(({ parent, interests }) => {

        let _interests = Object.keys(interests).reduce((a, b) => {

            let _actors = interests[b].map(idActor => toActorDictionary[idActor]);

            a[b] = {
                count: interests[b].length,
                name: b,
                actors: _actors,
                impact: _actors.reduce((_a, _b) => {
                    return _a + ((anheloPorcentaje[_b.anhelo] * 10) / 100)
                }, 0)
            }
            return a;
        }, {})
        return {
            parent,
            interests: _interests
        }
    })
}

// buildMetriscs() {
//     const { allAnswers, actor } = this.props;

//     let answersCompany = Object.assign([], allAnswers.filter(item => item.idActor == actor.id));
//     let answersCollaboratos = Object.assign([], allAnswers.filter(item => item.idActor != actor.id));

//     let results = {};

//     Object.keys(this.state.metrics).map((_metric) => {
//         results[_metric] = {
//             nombre: _metric,
//             valores: buildActions[_metric](answersCompany, answersCollaboratos)
//         }
//     })

//     return results;


// }

// selectAnhelo(anhelo) {

//     this.setState({
//         currentAnhelo: {
//             anhelo: anhelo.anheloNombre,
//             descripcion: anhelosdef[anhelo.anheloNombre].descripcion,
//             anhelosDef: anhelo.aspectos.map((item) => {
//                 return {
//                     aspecto: item.beneficio,
//                     descripcion: item.descripcion
//                 }
//             })
//         }
//     })

// }

// closeAnhelo() {
//     this.setState({
//         currentAnhelo: null
//     })
// }


export const buildTopBeneficios = (anhelos, allAnswers, actor, eneaBeneficios, anhelosBeneficios, actors) => {


    //Suma peso beneficios (consolidate-consolidateUnified)
    let sumBeneficios = anhelos.consolidateUnified.reduce((a, { anheloNombre, porcentaje, aspectos }) => {
        aspectos.forEach((aspecto) => {
            a[aspecto.beneficio] = getValue(a, 0, aspecto.beneficio) + Number(porcentaje);
        })
        return a;
    }, {})

    //Eneatipos por actor
    let _actores = Object.assign([], allAnswers
        .filter(item => (actors == undefined || actors.indexOf(String(item.idActor)) > -1))
        .filter(item => item.idActor != actor.id && item.eneatipo != 0))
        .reduce((a, b) => {
            a[b.idActor] = b.p_actor.eneatipo;
            return a;
        }, {});

    //conteo eneatipos
    let conteoEneatipos = Object.keys(_actores).filter((actor) => _actores[actor] != 0).reduce((a, b) => {
        a[_actores[b]] = a[_actores[b]] ? a[_actores[b]] + 1 : 1;
        return a;
    }, {});

    //pesos por eneatipo
    let pesosEnea = eneaBeneficios.reduce((a, b) => {
        a[b.enatipo] = Object.assign({}, a[b.enatipo]);
        a[b.enatipo][b.beneficio] = b.peso;
        return a;
    }, {})


    // calculo de porcentaje de personas en eneatipo X por beneficios
    let sumBeneficiosEnea = {}
    Object.keys(conteoEneatipos).map(eneatipo => {
        Object.keys(pesosEnea[eneatipo]).map(bene => {
            let peso = (conteoEneatipos[eneatipo] / Object.keys(_actores).length) * pesosEnea[eneatipo][bene];
            sumBeneficiosEnea[bene] = sumBeneficiosEnea[bene] ?
                peso + sumBeneficiosEnea[bene] : peso;
        })
    })


    let maxBeneficios = Object.keys(sumBeneficios).reduce((a, b) => a + sumBeneficios[b], 0)
    let maxBeneficiosEnea = Object.keys(sumBeneficiosEnea).reduce((a, b) => a + sumBeneficiosEnea[b], 0)


    let finalPeso = Object.keys(sumBeneficios).reduce((a, b, i, obj) => {
        let percentAnhelo = sumBeneficios[b] * 100 / maxBeneficios;
        let percentEnea = sumBeneficiosEnea[b] * 100 / maxBeneficiosEnea;
        a[b] = percentAnhelo + percentEnea;
        return a
    }, {})

    let beneficiosPriorizados = Object.keys(finalPeso).map(item => ({
        beneficio: item,
        valor: finalPeso[item]
    })).sort((a, b) => (a.valor < b.valor ? 1 : -1))



    let groupAnhelosBeneficios = anhelosBeneficios.reduce((a, b) => {
        a[b.beneficio] = Object.assign([], a[b.beneficio]);
        a[b.beneficio].push(b);
        return a;
    }, {})

    return beneficiosPriorizados.map(item => {
        return {
            beneficio: item.beneficio,
            valor: Number(item.valor).toFixed(2),
            anhelos: groupAnhelosBeneficios[item.beneficio].map((anBen) => {
                return {
                    anhelo: anhelosEnum[anBen.anhelonumber],
                    descripcion: anBen.descripcion
                }
            })
        }
    })
}



export const eventMarkup = {
    'summary': 'Biotica Personas',
    'location': 'http://biotica_personas.com',
    'description': 'Biotica, te invita a hacer una pausa, respira y continua.',
    'start': {
        'dateTime': '2020-05-27T09:00:00-07:00',
        "timeZone": "America/Los_Angeles"
    },
    'end': {
        'dateTime': '2015-05-28T17:00:00-07:00',
        "timeZone": "America/Los_Angeles"
    },
    'recurrence': [
        'RRULE:FREQ=DAILY;COUNT=15;INTERVAL=1;WKST=MO;BYDAY=MO,TU,WE,TH,FR'
    ],
    'attendees': [
        // {'email': 'q3.14tra@gmail.com'}
    ],
    'reminders': {
        'useDefault': false,
        'overrides': [
            { 'method': 'popup', 'minutes': 10 }
        ]
    }
};

// {'method': 'email', 'minutes': 24 * 60},
//   ,
//     'attendees': [
//       {'email': 'lpage@example.com'},
//       {'email': 'sbrin@example.com'}
//     ]


export const indPaths = [
    "M54.6,-66.4C69.2,-52.9,78.2,-34.1,82,-14.3C85.7,5.6,84.1,26.7,75.1,44.3C66,62,49.5,76.3,30.3,83.2C11.1,90.1,-10.7,89.7,-30.4,82.8C-50.2,76,-67.9,62.7,-77.9,45.1C-87.8,27.5,-90.1,5.6,-85.6,-14.2C-81.1,-34.1,-69.8,-51.9,-54.4,-65.3C-39,-78.6,-19.5,-87.5,0.3,-87.8C20,-88.1,40.1,-79.9,54.6,-66.4Z",
    "M58.3,-69.4C74.1,-56.2,84.4,-36.5,87.7,-15.8C90.9,4.9,87.1,26.5,76.8,44.6C66.6,62.7,50.1,77.3,30.5,84.9C11,92.5,-11.6,93.1,-30.9,85.7C-50.3,78.3,-66.5,63,-75.9,44.8C-85.3,26.5,-88,5.3,-84.5,-15C-81,-35.3,-71.5,-54.6,-56.4,-67.8C-41.3,-81.1,-20.7,-88.3,0.3,-88.7C21.3,-89,42.5,-82.5,58.3,-69.4Z",
    "M56.7,-69.3C71.1,-55.4,78.8,-35.3,81.8,-15C84.7,5.4,82.8,26.1,73.9,43.8C65,61.5,49,76.2,30.5,82.3C11.9,88.4,-9.2,85.9,-27.7,78.3C-46.3,70.6,-62.2,57.9,-71.6,41.4C-81,25,-83.9,4.8,-81,-14.7C-78,-34.2,-69.2,-53,-54.7,-66.9C-40.2,-80.8,-20.1,-89.6,0.5,-90.2C21.1,-90.8,42.2,-83.1,56.7,-69.3Z",
    "M57,-67.3C73,-54.6,84.3,-35.7,87.4,-15.8C90.5,4.2,85.3,25.2,74.7,42.7C64.1,60.3,48.2,74.4,29.7,80.6C11.3,86.9,-9.6,85.3,-29.2,78.8C-48.9,72.3,-67.3,60.9,-78.4,44.1C-89.5,27.3,-93.4,5.1,-89.1,-15.2C-84.9,-35.4,-72.6,-53.7,-56.4,-66.4C-40.2,-79.1,-20.1,-86.3,0.2,-86.6C20.5,-86.8,41.1,-80.1,57,-67.3Z",
    "M54,-63.9C70.3,-50.7,84,-34,87.2,-15.4C90.5,3.2,83.3,23.5,72.1,40.4C60.9,57.2,45.7,70.5,27.8,77.3C10,84.2,-10.5,84.6,-28.7,78.2C-46.9,71.9,-62.9,58.7,-72.1,42C-81.4,25.2,-84,5,-80.4,-14C-76.8,-32.9,-67.1,-50.6,-52.6,-64.1C-38.2,-77.7,-19.1,-87.1,-0.1,-87C18.9,-86.8,37.7,-77.1,54,-63.9Z"
]