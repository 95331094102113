import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Footer from './footer'
import Header from './header'
import Menu from './menu'


import { AnimatedSwitch } from 'react-router-transition';

// HashRouter as Router,
import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom'


import { BASE_ROUTE } from '../constantsGlobal'
import QuestionsContent from "../components/questionsContent"
import Profile from "../components/profile";

import RoteComponent from "../components/route";
import Retrospective from "../components/retrospective";

import Ecosistemic from "../components/ecosistemic";
import Feedback from "../components/feedback";
import FeedbackDetail from "../components/feedbackDetail";
import Definition from "../components/definition/definition";
import BreathingsTools from "../components/breathingsTools";
import Dashboard from "../components/dashboard";
import Scheduler from "../components/scheduler";

import SaturationQuestionContent from "../components/saturationQuestions/saturationQuestionContent";
import Alert from '../utils/alert'
// import Profile from "../components/profile";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const { pointsNotification, history } = this.props;

        return (
            <div className="App">
                <Header />
                <Menu />
                <main>
                    <AnimatedSwitch
                        atEnter={{ opacity: 0 }}
                        atLeave={{ opacity: 0 }}
                        atActive={{ opacity: 1 }}
                        className="switch-wrapper-child"
                    >
                        <Route exact path='/home/questions/' component={QuestionsContent}></Route>
                        <Route exact path='/home/profile/' component={Profile}></Route>
                        <Route exact path='/home/dashboard/' component={Dashboard}></Route>

                        <Route exact path='/home/route/' component={
                            pointsNotification.length > 0 ?
                                RoteComponent : SaturationQuestionContent
                        }></Route>
                        
                        <Route exact path='/home/retrospective/' component={ Retrospective }></Route>

                        {/* <Route exact path='/home/routeSaturationQuestions/' component={SaturationQuestionContent}></Route> */}
                        {/* <Route exact path='/home/route/' component={SaturationQuestionContent}></Route> */}


                        <Route exact path='/home/comunity/' component={Ecosistemic}></Route>
                        <Route exact path='/home/feedback/' component={Feedback}></Route>
                        <Route exact path='/home/feedbackDetail/' component={FeedbackDetail}></Route>
                        <Route exact path='/home/definition/' component={Definition}></Route>
                        <Route exact path='/home/breathingTools/' component={BreathingsTools}></Route>
                        <Route exact path='/home/scheduler/' component={Scheduler}></Route>

                        <Route path='/home/survey' component={() => {
                            window.location.href = 'https://es.surveymonkey.com/r/V3SVPRV';
                            return null;
                        }} />

                        {/* <Route exact path='/home/dashboard/' component={Dashboard}></Route>
                        <Route exact path='/home/motivadores/' component={Motivators}></Route>
                        <Route exact path='/home/intereses/' component={Interest}></Route>
                        <Route exact path='/home/habilidades/' component={Habilities}></Route> */}


                    </AnimatedSwitch>
                    {/* <Alert /> */}
                </main>
                {/* <Footer /> */}

            </div>

        );
    }
}


const mapStateToProps = state => ({
    pointsNotification: state.saturation.pointsNotification
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}
// getAllQuestios


export default connect(mapStateToProps, mapDispatchToProps)(Home);