import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import QuestionAnswer from './questionAnswer';

import _ from 'lodash'
import FA from 'react-fontawesome';

class AnswerMatriz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            values: {}
        }
        this.selectValue = this.selectValue.bind(this);


    }

    componentWillMount() {
        const { items, nItemsMatriz, value } = this.props;
        
        this.setState({ 
            items, 
            nItemsMatriz: _.fill(Array(nItemsMatriz), null),
            values: value.split(";").reduce((a, b) => {
                let answerValue = b.split("-");
                a[answerValue[0]] = answerValue[1]
                return a;
            }, {})
        })
    }

    selectValue(id, value) {
        const { selectAnswer } = this.props;

        let _values = Object.assign({}, this.state.values);
        _values[id] = value;

        this.setState({
            values: _values
        });

        let _valuesString = Object.keys(_values)
            .map(idAnswer => (`${idAnswer}-${_values[idAnswer]}`))
            .join(";")

        selectAnswer(_valuesString)
    }




    render() {
        // ${answers[question.id] == resp.id ? "active-radio" : ""} 
        // onChange={() => this.selectAnswer(resp.id)}
        return (
            <div className="content-answer-matriz">

                {this.state.nItemsMatriz.map((item, indx) => (<span style={{
                    gridColumn: `${indx + 2}`, gridRow: "1"
                }}>{indx + 1}</span>))}

                {this.state.items.map((item, indx) => (
                    <div style={{
                        gridColumn: "1", gridRow: `${indx + 2}`
                    }}>
                        <span>{item.respuesta}
                        </span>
                    </div>
                ))}

                {this.state.items.reduce((a, b, indx) => (
                    [...a, ...this.state.nItemsMatriz.map((item, indxSubItem) => ({
                        ...b, valieIndx: indxSubItem + 1, row: (indx + 2), column: (indxSubItem + 2)
                    }))]
                ), []).map(item => (
                    <div style={{
                        gridColumn: `${item.column}`, gridRow: `${item.row}`

                    }} >
                        <FA
                            name={this.state.values[item.id] == item.valieIndx ? "circle" : "circle-thin"}
                            style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color: "white" }}
                            onClick={() => this.selectValue(item.id, item.valieIndx)}
                        />
                    </div>
                ))}


            </div>
        )
    }

}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AnswerMatriz);
