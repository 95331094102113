import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loginAction } from './actions/actions'

import FontAwesome from 'react-fontawesome';

import { HOST_APP } from "./constantsGlobal"

// import LoginGoogle from './utils/loginGoogle'
import Redirect from './utils/redirect'

import FA from 'react-fontawesome';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ""
        }
        this.logIn = this.logIn.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        // const { loginAction } = this.props;
        // loginAction({
        //     code: "piloto12020"
        // })
    }

    logIn() {
        const { loginAction } = this.props;
        loginAction({
            code: this.state.codigo
        })
    }

    redirect() {
        const { history } = this.props;
        history.push('/home');
    }


    render() {
        const { isLogin, actor, history } = this.props;
        let _actor = Object.assign({}, actor);
        return (
            <section className="content-login">

                <div className="brand-content" style={{
                    backgroundImage: `url(${HOST_APP}/IMG_001-min.png)`
                }}>
                    <div style={{ backgroundImage: `url(${HOST_APP}/degradado_trans_bottom.jpg)` }}>
                        <img alt="brand resource" src={`${HOST_APP}/BIOTICA-WHITE.png`} />
                    </div>

                </div>
                <div className="form-content">
                    <div>
                        <div className="form-section">
                            <h2>Código</h2>
                            <FA
                                className="incon-hint"
                                name="circle-o"
                            />
                            <input onChange={(ev, val) => {
                                this.setState({ codigo: ev.currentTarget.value })
                            }} ></input>
                        </div>

                        <button style={{
                            backgroundImage: `url(${HOST_APP}/bottom_bck.png)`
                        }}

                            className="button-primary" onClick={this.logIn}>Ingresar</button>
                    </div>
                </div>

                {/* {isLogin && isMobile && <LoginGoogle onload={() => history.push('/home/questions/')} />} */}
                {isLogin && <Redirect currentHistory={history} route='/home/questions/' />}

            </section>
        );
    }
}


const mapStateToProps = state => ({
    isLogin: state.diagnosis.isLogin,
    actor: state.diagnosis.actor
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loginAction
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);
