import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setFooterValue, disableFooterNextValue } from '../actions/actions';
import { throws } from 'assert';

import FA from 'react-fontawesome';
import {
    BrowserRouter as Router,
    Route,
    NavLink,
    Link
} from 'react-router-dom'

const _routes = [
    { icon: "user-o", name: "Perfil", route: "/home/profile" },
    { icon: "road", name: "Ruta", route: "/home/route" },
    { icon: "users", name: "Comunidad", route: "/home/comunity" }
];

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: {}
        }

        this.setFooterValue = this.setFooterValue.bind(this);
    }
    setFooterValue(value) {
        const { footer, setFooterValue, disableFooterNextValue } = this.props;
        let _value = "";
        // eslint-disable-next-line default-case
        switch (value) {
            case "next":
                _value = footer.value + 1;
                break;
            case "prev":
                _value = footer.value - 1;
                break;
            case "save":
                _value = "saveinfo";
                break;
        }
        setFooterValue(_value);
        disableFooterNextValue();
    }


    render() {
        const { isLogin, actor, history, footer, setFooterValue } = this.props;

        // <aside>
        //     <nav className="menu">

        //         <section className="section-menu">
        //             <NavLink exact to={`/home/dashboard/`} activeClassName="active">
        //                 <FA name="tachometer" />
        //                 <span>DashBoard</span>
        //             </NavLink>
        //         </section>


        //         <section className="section-menu">
        //             <span className="caption">Capacidad</span>
        //             <NavLink exact to={`/home/habilidades/`} activeClassName="active">
        //                 <FA name="line-chart" />
        //                 <span>Habilidades</span>
        //             </NavLink>
        //         </section>

        //         <section className="section-menu">
        //             <span className="caption">Mi perfil</span>
        //             {/* <NavLink to={`/users/`} activeClassName="active">
        //                 <FA name="user-o" />
        //                 <span>Personas</span>
        //             </NavLink> */}
        //             <NavLink exact to={``} activeClassName="active">
        //                 <FA name="road" />
        //                 <span>Ruta</span>
        //             </NavLink>
        //         </section>


        //     </nav>


        // </aside>
        return (
            <footer>
                {footer.type == "steeps" &&
                    footer.childs.map((item, indx) => (
                        <span onClick={() => ((footer.allowChange || indx == 0) ? this.setFooterValue(item.value) : null)} style={{
                            color: `${(footer.allowChange || indx == 0) ? 'inherit' : 'rgba(57, 133, 153, 0.5)'}`,
                            display: `${item.getVisibility(footer.value) ? 'inherit' : 'none'}`
                        }} >
                            {item.name}
                        </span>
                    ))
                }
                {/* {footer.type == "save" &&
                    footer.childs.map(item => (
                        <span onClick={() => { this.setFooterValue(item.value) }}>
                            {item.name}
                        </span>
                    ))
                } */}


                {footer.type == "route" &&
                    footer.childs.map(item => (
                        <NavLink exact to={item.route} onClick={() => {
                            setFooterValue(item.name);
                        }} >
                            <FA name={item.icon} />
                            <span>{item.name}</span>
                        </NavLink>
                    ))
                }
            </footer>
        );
    }
}

const mapStateToProps = state => ({
    isLogin: state.diagnosis.isLogin,
    actor: state.diagnosis.actor,
    footer: state.footer,
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setFooterValue,
        disableFooterNextValue
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
