import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TimeRange from 'react-time-range';
import moment from 'moment';

class TimeRangeOptionsFieldQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lunch: {}
        }

        this.setRange = this.setRange.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
    }

    componentWillMount(){
        const { question: { quest, desc, options } } = this.props;

        this.setState({
            lunch: options.reduce((a,b,i) => {
                let dummyDate = new Date();
                dummyDate.setHours(9);
                dummyDate.setMinutes(0);
                a[b.id] = Object.assign({}, a[b.id])
                a[b.id].startTime = dummyDate; 
                a[b.id].endTime = dummyDate; 
                return a;
            },{})
        });
    }

    nextQuestion() {
        const { nextQuest, question} = this.props;

        nextQuest(question, this.state.lunch);
    }

    setRange({ endTime, startTime }, optionId) {

        let options = this.state.lunch;
        options[optionId] = {
            startTime: new Date(startTime),
            endTime: new Date(endTime)
        }

        this.setState({
            lunch : options
        })
    }


    render() {
        const { question: { quest, desc, options } } = this.props;

        let isValid = options.reduce((a, b, i) => {
            let flag = true;
            flag = (Object.assign({}, this.state.lunch[b.id]).startTime !== undefined) && flag;
            flag = (Object.assign({}, this.state.lunch[b.id]).endTime !== undefined) && flag;
            return a && flag;
        }, true)

        return (
            <div className="content-time-range-field-question">

                <h2>{quest}</h2>
                <p>{desc}</p>
                <div className="content-time-range-field">

                    {options.map(item => (<div className="item-time-range-field">
                        <span>{item.text}</span>
                        <TimeRange
                            startLabel="Inicio"
                            endLabel="Fin"
                            minuteIncrement={60}
                            sameIsValid={true}
                            showErrors={false}
                            startMoment={Object.assign({}, this.state.lunch[item.id]).startTime}
                            endMoment={Object.assign({}, this.state.lunch[item.id]).endTime}
                            onChange={(range)=>this.setRange(range, item.id)} />
                    </div>))}

                </div>

                <button className={`callback ${isValid ? "" : "disable"}`} onClick={this.nextQuestion} >Siguiente</button>
            </div>
        )
    }
}




const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeRangeOptionsFieldQuestion);