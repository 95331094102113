import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { imagesResources, personalInterst } from "../../resources/resources"


class QuestThumb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currInterest: [],
            helpText: {
                "Deportes": "Juego",
                "Música": "Escucho",
                "Arte": "Aprecio",
                "Literatura": "Leo",
                "Impacto social": "Disfruto",
                "Bienestar": "Disfruto",
                "Conocimiento": "Leo"
            }
        }

        this.addInterest = this.addInterest.bind(this);
    }



    addInterest(name, route, checked) {

        const { setInterest } = this.props;

        let currInterest = this.state.currInterest;
        currInterest = currInterest.filter((item) => item.interest != name);

        if (!checked) {
            currInterest.push({
                interest: name,
                route
            });
        }

        this.setState({
            currInterest
        })

        setInterest(currInterest);
    }

    getImage(name) {
        return Object.keys(imagesResources).filter((image) => image == name).reduce((a, b) => {
            return imagesResources[b]
        }, "./images/dummy.jpg")
    }


    render() {
        const { interests } = this.props;

        let groupInterest = interests.reduce((a, b) => {
            let parent = personalInterst[b.route.split(",").shift()].item;
            a[parent] = Object.assign([], a[parent]);
            a[parent].push(b);
            return a;
        }, {})

        let currInterest = this.state.currInterest.map((_interest) => _interest.interest);
        let currInterestParent = this.state.currInterest.map((_interest) => personalInterst[_interest.route.split(",").shift()].item);

        return (
            <div className="content-thumbs-section" >
                <p>De las siguientes actividades, cuales practicas actual mente.</p>
                {Object.keys(groupInterest).map((interestParent) => {
                    let isParentSelected = currInterestParent.indexOf(interestParent) > -1;
                    return <div className="section-thumb-item" >
                        <h3>{interestParent} [{this.state.helpText[interestParent]}]</h3>
                        <div className="horizontal-grid extended scroll-hide">
                            {groupInterest[interestParent].map(({ interest, route }) => {
                                let checked = currInterest.indexOf(interest) > -1;
                                return <div className={`quest-thumb-grid-item ${checked ? "check" : ""}`} onClick={() => { this.addInterest(interest, route, checked ) }}>
                                    <h3>{interest}</h3>
                                    <div style={{ backgroundImage: `url('${this.getImage(interest)}')` }} className="fade-back-thumb">
                                        <span></span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                })}

            </div>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestThumb);
