import {
    LOGIN_REDUCE, SET_ACTOR_REDUCE, SET_QUESTIONS_REDUCE, GET_ANHELOS_REDUCE, SET_ANSWERS_REDUCE, SET_ALL_ANSWERS_REDUCER,
    SET_ANHELOS_BENEFICIOS_REDUCER, SET_ALL_ENEA_QUESTIOS_REDUCER, SET_ENEA_BENEFICIOS_REDUCER,
    SET_INTEREST_ACTOR_REDUCE, SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER, SET_ALL_INTERSTEDS_ACTORS_REDUCER, 
    SET_ALL_ACTORS_COMPANY_REDUCER
} from '../constantsGlobal';

const initialState = {
    isLogin: false,
    actor: {},
    questios: [],
    answers: [],
    anhelos: [],
    allAnswers: [],
    anhelosBeneficios: [],
    eneaQuestios: [],
    eneaBeneficios: [],
    interests: [],
    countInterests: [],
    allInterests: [],
    colaborators: {},
}

function diagnosis(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REDUCE:
            return Object.assign({}, state, {
                isLogin: action.payload
            });
        case SET_ACTOR_REDUCE:
            return Object.assign({}, state, {
                actor: action.payload
            });
        case SET_QUESTIONS_REDUCE:
            return Object.assign({}, state, {
                questios: action.payload
            });
        case SET_ANSWERS_REDUCE:
            // console.log("SET_ANSWERS_REDUCE",action.payload);
            return Object.assign({}, state, {
                answers: action.payload
            });
        case GET_ANHELOS_REDUCE:
            return Object.assign({}, state, {
                anhelos: action.payload
            });
        case SET_ALL_ANSWERS_REDUCER:
            return Object.assign({}, state, {
                allAnswers: action.payload
            });
        case SET_ANHELOS_BENEFICIOS_REDUCER:
            return Object.assign({}, state, {
                anhelosBeneficios: action.payload
            });
        case SET_ALL_ENEA_QUESTIOS_REDUCER:
            return Object.assign({}, state, {
                eneaQuestios: action.payload
            });

        case SET_ENEA_BENEFICIOS_REDUCER:
            return Object.assign({}, state, {
                eneaBeneficios: action.payload
            });

        case SET_INTEREST_ACTOR_REDUCE:
            return Object.assign({}, state, {
                interests: action.payload
            });

        case SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER:
            return Object.assign({}, state, {
                countInterests: action.payload
            });

        case SET_ALL_INTERSTEDS_ACTORS_REDUCER:
            return Object.assign({}, state, {
                allInterests: action.payload
            });
        
        case SET_ALL_ACTORS_COMPANY_REDUCER:
            return Object.assign({}, state, {
                colaborators: action.payload
            });

        default:
            return state
    }
}

export default diagnosis;