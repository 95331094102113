import {
    GET_SATURATION_POINT_NOTIFICATION_SERVICE, GET_SATURATION_POINT_NOTIFICATION_SAGA, SET_SATURATION_POINT_NOTIFICATION_REDUCER,
    PUT_SATURATION_POINT_NOTIFICATION_SERVICE, PUT_SATURATION_POINT_NOTIFICATION_SAGA
} from '../constantsGlobal'

import { call, put, takeEvery, takeLatest, fork, all, select } from 'redux-saga/effects'

import axios from 'axios'
import { deprecate } from 'util';

function* getSaturationPointNotification(action) {
    const points = yield call(axios.post, GET_SATURATION_POINT_NOTIFICATION_SERVICE, {
        idActor: action.payload
    });

    yield put({
        type: SET_SATURATION_POINT_NOTIFICATION_REDUCER, payload: points.data
    });

}


function* putSaturationPointNotification(action) {
    const points = yield call(axios.post, PUT_SATURATION_POINT_NOTIFICATION_SERVICE, {
        saturationPoints: action.payload
    });

    yield put({
        type: SET_SATURATION_POINT_NOTIFICATION_REDUCER, payload: points.data
    });

}



export function* getSaturationPointNotificationSaga() {
    yield takeLatest(GET_SATURATION_POINT_NOTIFICATION_SAGA, getSaturationPointNotification);
}


export function* putSaturationPointNotificationSaga() {
    yield takeLatest(PUT_SATURATION_POINT_NOTIFICATION_SAGA, putSaturationPointNotification);
}
