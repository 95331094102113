import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { unSetDefinitionItem, setCurrentContextCategoryFeedback, putTimeBreathing } from '../../actions/actions'


import FeedbackDetail from '../feedbackDetail'
import BreathIndicator from '../breath/breathIndicator'

let interval = null;
let _promise = null;

class Definition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            context: {},
            category: {},
            doit: false,
            initBreathProscess: false,
            configBreathDefaultTime: 0
        };

        this.setCurrentContextCategory = this.setCurrentContextCategory.bind(this);
        this.initBreathing = this.initBreathing.bind(this);
    }

    componentDidMount() {
        const { definitionItem, contexts, actor, history, configBreathDefault } = this.props;


        if (!actor.id) {
            history.push("/");
        }


        // console.log(contexts, definitionItem);

        let ctxFeedBack = contexts.filter(context => context.id === definitionItem.contextId)
            .map(context => {
                return {
                    ...context,
                    category: context.p_feedback_categories
                        .filter(category => category.id === definitionItem.categoryId).reduce((a, b) => b, {})
                }
            }).reduce((a, b) => b, {})

        this.setState({
            context: ctxFeedBack,
            category: ctxFeedBack.category,
            countBreathing: 0,
            configBreathDefaultTime: configBreathDefault.time
        })



    }

    initBreathing() {
        const { definitionItem, configBreathDefault, putTimeBreathing } = this.props;
        this.setState({
            initBreathProscess: true
        })

        if (definitionItem.currentBreathing) {
            new Promise((resolve) => {
                _promise = resolve;
                interval = setInterval(() => {
                    let _seconds = (this.state.configBreathDefaultTime * 60)
                    if (_seconds <= this.state.countBreathing) {
                        clearInterval(interval);
                        resolve()
                    } else {
                        if (this.state.countBreathing !== 0 && this.state.countBreathing % (_seconds / 6) === 0) {
                            // console.log(`put seconds ${this.state.countBreathing}`)
                            putTimeBreathing(_seconds / 6)
                        }
                        this.setState({
                            countBreathing: this.state.countBreathing + 1
                        })
                    }
                }, 1000)
            }).then(() => this.setState({
                doit: true
            }))
        }
    }

    // componentWillUnmount() {
    //     const { unSetDefinitionItem } = this.props;
    //     unSetDefinitionItem();
    // }

    setCurrentContextCategory(context, category) {
        // const { setCurrentContextCategoryFeedback, history } = this.props;

        // setCurrentContextCategoryFeedback({
        //     context: this.state.context, category: this.state.category
        // })

        // history.push("/home/feedbackDetail/");
        if (interval) {
            _promise();
            clearInterval(interval);
        }

        this.setState({
            doit: true
        })
    }
    componentWillUnmount() {
        if (interval) {
            _promise();
            clearInterval(interval);
        }
    }


    render() {
        const { definitionItem, history, configBreathDefault } = this.props;
        return (
            <div className="content-definicion">
                <h2>{definitionItem.title}</h2>
                <div className="main-definicion">

                    <div className="content-left">



                        {(this.state.context && this.state.category) &&
                            <FeedbackDetail
                                hideTitle={true}
                                currentContext={this.state.context}
                                curretnCategory={this.state.category}
                                history={history}
                                origin={definitionItem.origin}
                            />

                        }
                        {!this.state.doit &&
                            <div className="fade-feedback"></div>
                        }
                    </div>
                    <div className="content-rigth">
                        <h3>Guía</h3>
                        <div className="definition-description-content">
                            <p>{definitionItem.description}</p>
                            {definitionItem.note &&
                                <p>{definitionItem.note}</p>
                            }
                        </div>

                        {definitionItem.currentBreathing &&
                            <div className="content-breath-count">


                                {!this.state.initBreathProscess && <div className="content-breath-init">

                                    <div >
                                        <select onChange={(e) => this.setState({
                                            configBreathDefaultTime: e.currentTarget.value
                                        })}>
                                            <option selected value={configBreathDefault.time} > default {configBreathDefault.time} min</option>
                                            <option value={1}>1 min</option>
                                            <option value={2}>2 min</option>
                                            <option value={3}>3 min</option>
                                            <option value={4}>4 min</option>
                                            <option value={5}>5 min</option>
                                            <option value={6}>6 min</option>
                                            <option value={7}>7 min</option>
                                            <option value={8}>8 min</option>
                                            <option value={9}>9 min</option>
                                            <option value={10}>10 min</option>
                                        </select>
                                        <span onClick={this.initBreathing}>Iniciar</span>
                                    </div>

                                </div>}

                                <BreathIndicator doit={this.state.doit} definitionItem={definitionItem} limitTime={this.state.configBreathDefaultTime}
                                    initBreathProscess={this.state.initBreathProscess} />
                                <span> {(this.state.configBreathDefaultTime * 60) - this.state.countBreathing} <small>Seg</small> </span>
                            </div>

                        }

                        <div className="definition-steps-content">
                            {Object.assign([], definitionItem.steps).filter(item => item.title).map((item, indx) => (
                                <div key={`definition-step-item-${indx}`} className="definition-step-item">
                                    <h4>{item.title}</h4>
                                    {item.description && <p>{item.description}</p>}
                                </div>
                            ))}

                        </div>
                        {(this.state.context.id && !this.state.doit &&
                            (!definitionItem.currentBreathing || (definitionItem.currentBreathing && this.state.initBreathProscess))) &&
                            <div className="content-callback">
                                <span onClick={this.setCurrentContextCategory} className="callback">Hecho</span>
                            </div>
                        }
                    </div>

                </div>





            </div>
        )
    }
}



const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    definitionItem: state.definition.definitionItem,
    contexts: state.feedback.contexts,
    configBreathDefault: Object.assign({}, state.utils.config.breath)
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        unSetDefinitionItem,
        setCurrentContextCategoryFeedback,
        putTimeBreathing
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Definition);
