import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment'
import FA from 'react-fontawesome'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

import { putNewProyect, deleteProyect, putNewTasks, deleteTask } from '../actions/actions'

import { enumScheduleType, enumScheduleTypeText, optionsTaskModal } from '../constantsGlobal'



class Scheduler extends Component {


    constructor(props) {
        super(props);

        this.state = {
            proyectName: "",
            taskProyectId: "",
            taskName: "",
            taskDescription: "",
            timePickervalue: moment(new Date()),
            showModalProyect: false,
            showModalTask: false,
            submitModalProyect: false,
            submitModalTask: false,
            shwoAlert: false,
            resolveMessage: null,
            alertMessage: "",
            showModalTaskOptions: false,
            modalTaskOptions: [],
            enableOterOption: false
        }

        this.setTaskOption = this.setTaskOption.bind(this);
        this.removeProyect = this.removeProyect.bind(this);
        this.removeTask = this.removeTask.bind(this);
        this.createProyect = this.createProyect.bind(this);
        this.createTask = this.createTask.bind(this);
        this.createTaskProyect = this.createTaskProyect.bind(this);

    }


    componentWillMount() {

        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }
    }
    componentWillUnmount() {
        if (this.state.resolveMessage) {
            this.state.resolveMessage(false)
        }
    }

    removeProyect(idProyect) {
        const { deleteProyect } = this.props;

        new Promise((resolve) => {
            this.setState({
                resolveMessage: resolve,
                shwoAlert: true,
                alertMessage: `Esta seguro de eliminar el proyecto.`
            })
        }).then((result) => {
            if (result)
                deleteProyect(idProyect);


            this.setState({
                shwoAlert: false,
                resolveMessage: null,
                alertMessage: ""
            })

        })
    }

    removeTask(idTask, idProyect) {

        const { deleteTask } = this.props;

        new Promise((resolve) => {
            this.setState({
                resolveMessage: resolve,
                shwoAlert: true,
                alertMessage: `Esta seguro de eliminar la tarea.`
            })
        }).then((result) => {
            if (result)
                deleteTask({
                    proyectId: idProyect,
                    taskId: idTask
                });


            this.setState({
                shwoAlert: false,
                resolveMessage: null,
                alertMessage: ""
            })

        })
    }

    createProyect() {
        const { putNewProyect } = this.props;
        this.setState({
            submitModalProyect: true
        })

        if (this.state.proyectName) {
            putNewProyect(this.state.proyectName);
            this.setState({
                proyectName: "",
                submitModalProyect: false,
                showModalProyect: false
            })
        }
    }

    createTask() {
        const { putNewTasks } = this.props;
        this.setState({
            submitModalTask: true
        })

        if (this.state.taskName && this.state.taskProyectId && this.state.timePickervalue) {
            putNewTasks({
                name: this.state.taskName,
                proyectId: this.state.taskProyectId,
                description: this.state.taskDescription,
                taskDate: this.state.timePickervalue.toDate().getTime()
            });

            this.setState({
                taskName: "",
                taskProyectId: "",
                taskDescription: "",
                timePickervalue: moment(new Date()),
                submitModalTask: false,
                showModalTask: false,
                showModalTaskOptions: false,
                enableOterOption: false,
                modalTaskOptions: []
            })
        }
    }

    setTaskOption(task) {
        this.setState({
            taskName: task !== "Otro" ? task : "",
            enableOterOption: task === "Otro"
        })
    }

    createTaskProyect(idProyect) {
        let optionsModal = [enumScheduleType.BREACK, enumScheduleType.HOBBIES];
        this.setState({
            showModalTaskOptions: optionsModal.indexOf(idProyect) > -1,
            showModalTask: optionsModal.indexOf(idProyect) === -1,
            taskProyectId: idProyect,
            modalTaskOptions: optionsTaskModal[idProyect]
        })

    }

    render() {
        const { actorTools } = this.props;

        let proyects = actorTools ? Object.assign({}, actorTools.proyects) : {};




        let countBreackToday = Object.keys(proyects)
            .filter(item => enumScheduleType.BREACK === item)
            .reduce((a, idProyect) => ([
                ...a,
                ...Object.keys(Object.assign({ tasks: {} }, proyects[idProyect]).tasks)
                    .map(id => proyects[idProyect].tasks[id])
            ]), [])
            .filter(task => moment(new Date(task.creationDate)).isSame(new Date(), 'day'))
            .length;

        let countHobbiesThisWeek = Object.keys(proyects)
            .filter(item => enumScheduleType.HOBBIES === item)
            .reduce((a, idProyect) => ([
                ...a,
                ...Object.keys(Object.assign({ tasks: {} }, proyects[idProyect]).tasks)
                    .map(id => proyects[idProyect].tasks[id])
            ]), [])
            .filter(task => moment(new Date(task.creationDate)).isSame(new Date(), 'week'))
            .length;

        return (
            <div className="content-scheduler">

                <h2>Planifica tu día</h2>

                <div className="content-main-scheduler">
                    <div className="content-proyects" >
                        <div>
                            <div className="content-callback-title">
                                <h3>Hoy</h3>
                            </div>
                            <hr />
                        </div>

                        {Object.keys(enumScheduleTypeText).map(id => ({ name: enumScheduleTypeText[id], id }))
                            .map(proyect => (
                                <div className="item-proyect" onClick={() => this.createTaskProyect(proyect.id)}>
                                    <h4>{proyect.name}</h4>
                                    <span>Nueva tarea</span>
                                    {/* <span>{moment(new Date()).lang("es").format('dddd D MMM')}  </span> */}
                                </div>
                            ))
                        }



                    </div>
                    <div className="content-task">
                        <div className="content-task-notification" >
                            <div className={`item-notification ${countBreackToday < 3 ? "notification-danger" : "notification-success"}`}>
                                {countBreackToday < 3 &&
                                    <p>Te {countBreackToday === 1 ? "falta" : "faltan"} {3 - countBreackToday} {countBreackToday === 1 ? "pausa activa" : "pausas activas"} de <strong>minimo</strong> 5 minutos, para el <strong>dia</strong> de hoy.</p>
                                }
                                {countBreackToday >= 3 && <p>Felicidades!,<br /> has cumplido tu cuota de breaks para el dia de hoy.</p>}

                                <FA name="info-circle" />
                            </div>
                            <div className={`item-notification ${countHobbiesThisWeek < 5 ? "notification-warning" : "notification-success"}`}>
                                {countHobbiesThisWeek < 5 &&
                                    <p>Llevas {countHobbiesThisWeek} {countHobbiesThisWeek === 1 ? "hobby": "hobbies"} esta estamana, te faltan {5 - countHobbiesThisWeek}, ¡animo!.</p>
                                }
                                {countHobbiesThisWeek >= 5 && <p>Felicidades!,<br /> has cumplido tu cuota de hobbies para esta semana.</p>}

                                <FA name="info-circle" />
                            </div>
                        </div>

                        <div className="content-task-title" >
                            <div className="content-callback-title">
                                <h3>Tareas</h3>
                                {/* <div>
                                    <span onClick={() => this.setState({
                                        showModalTask: true
                                    })}> Nueva tarea</span>
                                </div> */}
                            </div>
                            <hr />
                        </div>
                        {(Object.keys(proyects).length === 0 || Object.keys(proyects).map(id => ({ ...proyects[id], id }))
                            .reduce((a, b) => ([...a, ...Object.keys(b.tasks ? b.tasks : {})
                                .map(id => ({ ...b.tasks[id], id, proyectName: b.name, proyectId: b.id }))
                            ]), [])
                            .filter(task =>
                                moment(new Date(task.creationDate)).format('YYMD') === moment(new Date()).format('YYMD')).length === 0) &&
                            <p>No has registrado tareas.</p>
                        }

                        {Object.keys(proyects).map(id => ({ ...proyects[id], id }))
                            .reduce((a, b) => ([...a, ...Object.keys(b.tasks ? b.tasks : {})
                                .map(id => ({ ...b.tasks[id], id, proyectName: b.name, proyectId: b.id }))
                            ]), [])
                            .filter(task =>
                                moment(new Date(task.creationDate)).format('YYMD') === moment(new Date()).format('YYMD'))
                            .sort((a, b) => (new Date(a.taskDate)).getTime() - (new Date(b.taskDate)).getTime())
                            .map(task => (
                                <div className="item-task">
                                    <h4>{task.name}</h4>
                                    <div>
                                        <span>{task.proyectName}</span>
                                        <span>{moment(new Date(task.taskDate)).format('hh:mm a')}  </span>

                                    </div>
                                    <FA name="remove" onClick={() => this.removeTask(task.id, task.proyectId)} />
                                </div>
                            ))
                        }

                    </div>
                </div>
                {this.state.showModalProyect &&
                    <div className="modal">
                        <div className="modal-content">
                            <h4>Nuevo Proyecto</h4>
                            <div className="input-content">
                                <label for="proyectName">Nombre</label>
                                <input name="proyectName" id="proyectName"
                                    onChange={(e) => this.setState({
                                        proyectName: e.currentTarget.value
                                    })}></input>
                                {(this.state.submitModalProyect && this.state.proyectName === "") && <p>Este campo es obligatorio</p>}
                            </div>
                            <div>
                                <span onClick={this.createProyect}>Crear</span>
                            </div>
                        </div>
                        <div className="modal-fade" onClick={() => this.setState({
                            showModalProyect: false
                        })}>
                        </div>
                    </div>}

                {this.state.showModalTask &&
                    <div className="modal">

                        <div className="modal-content">
                            <h4>Nueva Tarea</h4>
                            <div>
                                {this.state.taskProyectId === "" &&
                                    <div className="input-content">
                                        <div className="content-options">

                                            {Object.keys(enumScheduleTypeText).map(id => ({ name: enumScheduleTypeText[id], id }))
                                                .map((proyect, indx) => (
                                                    <label>
                                                        <span>{proyect.name}</span>
                                                        <input key={`option_${indx}`} type="radio" name="proyectId" onClick={(e) => this.setState({
                                                            taskProyectId: proyect.id
                                                        })} />
                                                    </label>
                                                ))}
                                        </div>
                                        {(this.state.submitModalTask && this.state.taskProyectId === "") && <p>Este campo es obligatorio</p>}

                                    </div>
                                }

                                <div className="input-content">
                                    <label for="taskName">Tarea*</label>
                                    <input name="taskName" id="taskName"
                                        onChange={(e) => this.setState({
                                            taskName: e.currentTarget.value
                                        })}></input>
                                    {(this.state.submitModalTask && this.state.taskName === "") && <p>Este campo es obligatorio</p>}
                                </div>

                                <div className="input-content">
                                    <label for="taskDescription">Hora*</label>
                                    <TimePicker value={this.state.timePickervalue} onChange={(value) => this.setState({ timePickervalue: value })}
                                        showSecond={false} showMinute={true} format={'hh:mm a'} use12Hours={true} />
                                    {(this.state.submitModalTask && !this.state.timePickervalue) && <p>Este campo es obligatorio</p>}
                                </div>


                            </div>
                            <div>
                                <span onClick={this.createTask}>Crear</span>
                            </div>
                        </div>
                        <div className="modal-fade" onClick={() => this.setState({
                            showModalTask: false
                        })}>
                        </div>
                    </div>}

                {this.state.showModalTaskOptions &&
                    <div className="modal">

                        <div className="modal-content">
                            <h4>Nueva Tarea</h4>
                            <div>
                                <div className="input-content">

                                    <label for="taskProyectId">Tarea*</label>
                                    <select id="taskProyectId" name="taskProyectId"
                                        onChange={(e) => this.setTaskOption(e.currentTarget.value)}>
                                        <option value={null} selected>Seleccione...</option>
                                        {this.state.modalTaskOptions.map(_option => (
                                            <option value={_option}>{_option}</option>
                                        ))}
                                    </select>
                                    {(this.state.submitModalTask && this.state.taskName === "" && !this.state.enableOterOption) && <p>Este campo es obligatorio</p>}

                                </div>
                                {this.state.enableOterOption &&
                                    <div className="input-content">
                                        <label for="taskName">Otro*</label>
                                        <input name="taskName" id="taskName"
                                            onChange={(e) => this.setState({
                                                taskName: e.currentTarget.value
                                            })}></input>
                                        {(this.state.submitModalTask && this.state.taskName === "") && <p>Este campo es obligatorio</p>}
                                    </div>
                                }

                                <div className="input-content">
                                    <label for="taskDescription">Hora*</label>
                                    <TimePicker value={this.state.timePickervalue} onChange={(value) => this.setState({ timePickervalue: value })}
                                        showSecond={false} showMinute={true} format={'hh:mm a'} use12Hours={true} />
                                    {(this.state.submitModalTask && !this.state.timePickervalue) && <p>Este campo es obligatorio</p>}
                                </div>

                            </div>
                            <div>
                                <span onClick={this.createTask}>Crear</span>
                            </div>
                        </div>
                        <div className="modal-fade" onClick={() => this.setState({
                            showModalTaskOptions: false
                        })}>
                        </div>
                    </div>}

                {this.state.shwoAlert && <div className="alert-confirm">

                    <p>{this.state.alertMessage}</p>
                    <div>
                        <span onClick={() => this.state.resolveMessage(true)} >Aceptar</span>
                        <span onClick={() => this.state.resolveMessage(false)} >Cancelar</span>
                    </div>
                </div>}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools
});
const mapDispatchToProps = dispatch => bindActionCreators({
    putNewProyect, deleteProyect, putNewTasks, deleteTask
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
