import { SET_SATURATION_POINT_NOTIFICATION_REDUCER } from '../constantsGlobal';

const initialState = {
    pointsNotification: []
}

function saturation(state = initialState, action) {
    switch (action.type) {
        case SET_SATURATION_POINT_NOTIFICATION_REDUCER:
            return Object.assign({}, state, {
                pointsNotification: action.payload
            });
        default:
            return state
    }
}

export default saturation;