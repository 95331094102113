import React, { Component } from 'react';
import { connect } from 'react-redux';

class AtomicQuestion extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const { onLoadComponent } = this.props;

        if (onLoadComponent) {
            onLoadComponent()
        }

    }

    render() {
        const { questio, options, gridView } = this.props;

        return (
            <div className="item-center atomic-quest" >
                <h2>{questio}</h2>
                {!gridView &&
                    <div className={`grid-content single`}>
                        {options}
                    </div>
                }


                {gridView &&
                    <div className={`grid-content ${(options.length % 2) == 1 ? "qsquare" : "square"}`}>
                        {options}
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(AtomicQuestion);
