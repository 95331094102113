import {
    LOGIN_SERVICE, LOGIN_REDUCE, LOGIN_SAGA, SET_ACTOR_REDUCE, SET_QUESTIONS_SAGA,
    GET_ANHELOS_SAGA, GET_ANSWERS_SAGA, GET_ALL_ENEA_QUESTIOS_SAGA, GET_ACTOR_SAGA,
    GET_INTEREST_ACTOR_SAGA, GET_CONTEXT_FEEDBACK_SAGA, GET_SATURATION_POINT_NOTIFICATION_SAGA,
    GET_TOOLS_SAGA,
    GET_CONFIG_SERVICE, GET_CONFIG_SAGA, SET_CONFIG_REDUCER,
    INIT_WATCH_TOOLS_SAGA
} from '../constantsGlobal'

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import axios from 'axios'



import firebase from "firebase";

import rsf from '../utils/config'



function* logIn(action) {
    const questios = yield call(axios.post, LOGIN_SERVICE, {
        codigo: action.payload.code
    });

    if (questios.data) {
        yield put({
            type: LOGIN_REDUCE, payload: true
        });
        yield put({
            type: SET_ACTOR_REDUCE, payload: questios.data
        });
        yield put({
            type: SET_QUESTIONS_SAGA, payload: questios.data.typeActor
        });
        yield put({
            type: GET_ANSWERS_SAGA, payload: questios.data.id
        });
        yield put({
            type: GET_INTEREST_ACTOR_SAGA, payload: questios.data.id
        });

        yield put({
            type: GET_ANHELOS_SAGA
        });

        yield put({
            type: GET_TOOLS_SAGA
        });

        yield put({
            type: GET_CONFIG_SAGA
        });

        yield put({
            type: GET_SATURATION_POINT_NOTIFICATION_SAGA, payload: questios.data.id
        });
        
        yield put({
            type: INIT_WATCH_TOOLS_SAGA
        });

        if (questios.data.p_team_actors && questios.data.p_team_actors.length > 0) {
            yield put({
                type: GET_CONTEXT_FEEDBACK_SAGA, payload: questios.data.p_team_actors.map(ta => ta.idTeam)
            });
        }

        if (!questios.data.eneatipo || questios.data.eneatipo === 0) {
            yield put({
                type: GET_ALL_ENEA_QUESTIOS_SAGA
            });
        }
    }

}



function* getActor(action) {
    const questios = yield call(axios.post, LOGIN_SERVICE, {
        codigo: action.payload
    });

    yield put({
        type: SET_ACTOR_REDUCE, payload: questios.data
    });

}


function* getConfig() {

    const config = yield rsf.database.read(GET_CONFIG_SERVICE);
    // console.log(config)
    yield put({
        type: SET_CONFIG_REDUCER, payload: config
    });

}


export function* logInSaga() {
    yield takeEvery(LOGIN_SAGA, logIn);
}

export function* getActorSaga() {
    yield takeEvery(GET_ACTOR_SAGA, getActor);
}


export function* getConfigSaga() {
    yield takeLatest(GET_CONFIG_SAGA, getConfig);
}