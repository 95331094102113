import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { definitions } from '../resources/resources'

import { setDefinitionItem } from '../actions/actions'
import { enumFeedbackContext } from '../constantsGlobal'

import moment from 'moment'


class BreathingsTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidate: {},
            predominante: {},
            chartAnhelo: [],
            modalAsertOpen: false,
            showModal: false
        }

        this.selectBreathTool = this.selectBreathTool.bind(this);
    }



    componentWillMount() {

        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }
    }

    selectBreathTool(breathTool) {
        const { tools: { breathing }, setDefinitionItem, history, feedbackTools, configBreathDefault } = this.props;


        setDefinitionItem({
            ...breathTool,
            currentBreathing: !!breathing.find(brth => brth.id === configBreathDefault.id),
            contextId: feedbackTools.breathing.id,
            categoryId: feedbackTools.breathing.category.feedback,
            origin: "/home/breathingTools/"
        });
        history.push("/home/definition/");

    }

    render() {
        const { tools: { breathing }, configBreathDefault } = this.props;
        let currentDate = new Date();
        let currentMinutes = currentDate.getMinutes();
        let currentHour = currentDate.getHours();
        let [Lmin, Lmax] = configBreathDefault.limit ? configBreathDefault.limit.split(",") : [0, 0];


        let nextSpace = "";

        let _date = new Date();
        _date.setMinutes(0);

        if (["0", "6"].indexOf(moment(_date).format('d')) !== -1) {
            _date.setHours(Lmin);
            nextSpace = `El lunes ${moment(_date).format('h:mm a')}`
        } else if (currentHour < Number(Lmax) && currentHour > Number(Lmin) && currentMinutes < 15) {
            nextSpace = "A hora";
        } else if (currentHour < Number(Lmax) && currentHour > Number(Lmin)) {
            _date.setHours(currentHour + 1);
            nextSpace = `en ${moment(_date).diff(moment(currentDate), 'minutes')} Minutos`
        } else if (currentHour < Number(Lmin)) {
            _date.setHours(Lmin);
            nextSpace = `en ${moment(_date).diff(moment(currentDate), 'hours')} Horas`
        } else if (currentHour > Number(Lmax)) {
            _date.setHours(Lmin);
            nextSpace = `Mañana ${moment(_date).format('h:mm a')}`
        }




        return (
            <div className="content-breathing-tools">

                <h2>Respiración</h2>


                <div className="content-main-tool">
                    {breathing.filter(brth => brth.id === configBreathDefault.id)
                        .map((brth, indx) => (
                            <div key={`breath-current-item-${indx}`} className="breath-item-current" onClick={() => this.selectBreathTool(brth)}>
                                <div>
                                    <div>
                                        <h4>{brth.title}</h4>
                                        <span>{brth.description}</span>
                                    </div>
                                    <div>
                                        <span><strong> Próximo espacio:</strong> {nextSpace} </span>
                                        <span><strong> Frecuencia:</strong>
                                            Cada {configBreathDefault.frecuency == 1 ? "Hora" :
                                                `${configBreathDefault.frecuency} Horas`}
                                        </span>
                                        <span><strong> Duración:</strong>
                                        Cada {configBreathDefault.time == 1 ? "Minuto" :
                                                `${configBreathDefault.time} Minutos`}
                                        </span>
                                    </div>

                                </div>
                                <div className="content-callback">
                                    <span>Ver mas</span>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="content-tools">
                    {breathing.map((brth, indx) => (
                        <div key={`breath-item_${indx}`} className="breath-item" onClick={() => this.selectBreathTool(brth)}>
                            <h4>{brth.title}</h4>
                            <span>{brth.description}</span>
                            <div className="content-callback">
                                <span>Ver mas</span>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        )
    }
}




const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    definitionItem: state.definition.definitionItem,
    tools: state.definition.tools,
    feedbackTools: Object.assign({}, state.utils.config.feedbackTools),
    configBreathDefault: Object.assign({}, state.utils.config.breath)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setDefinitionItem
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(BreathingsTools);
