import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactSVG } from 'react-svg';

import BreathIndicatorPath from './breathIndicatorPath'
import { indPaths, HOST_APP } from '../../constantsGlobal'

import moment from 'moment';



let __indx = 5;
let globalResolve = null;
let globalResolveCount = null;
let globalTimeOut = null;
let globalInterval = null;

const audioInit = new Audio(`${HOST_APP}sounds/tibetan.mp3`);
const audioFinish = new Audio(`${HOST_APP}sounds/tibetan.mp3`);
// const audioAction = new Audio(`${HOST_APP}sounds/ring.mp3`);

class breathIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: "AFTER-OUT",
            actionText: "",
            limitMins: 1,
            initTime: moment(new Date()),
            currBreadStep: 1,
            steps: {
                "IN": 8,
                "AFTER-IN": 4,
                "OUT": 8,
                "AFTER-OUT": 2
            },
            stepsText: {
                "IN": "Inhala",
                "AFTER-IN": "Retiene",
                "OUT": "Exhala",
                "AFTER-OUT": "Sostiene"
            },
            stepsSound: {
                "IN": "ring.mp3",
                "AFTER-IN": "ring.mp3",
                "OUT": "ring.mp3",
                "AFTER-OUT": "ring.mp3",
                "INIT": "init.mp3",
                "FINISH": "end.mp3"
            },

        }

        // this.getClassBreathAnimation = this.getClassBreathAnimation.bind(this);
        this.clearGlobals = this.clearGlobals.bind(this);
        this.breathCicle = this.breathCicle.bind(this);
        this.initBreath = this.initBreath.bind(this);
    }

    componentWillMount() {
        const { definitionItem } = this.props;

        this.setState({
            steps: Object.assign([], definitionItem.steps)
                .filter(step => step.key)
                .reduce((a, b) => {
                    a[b.key] = b.time;
                    return a;
                }, this.state.steps),

        })

    }

    componentDidMount() {
        // new Promise(resolve => {
        //     this.breathCicle(resolve, this.state.action, this.state.steps[this.state.action])
        // }).then(() => {
        //     console.log("goodJob")
        //     clearInterval(globalInterval);
        // })

        // new Promise(resolve => {
        //     globalInterval = setInterval(() => {
        //         let action = this.state.action;
        //         let tAction = this.state.steps[action];
        //         if (tAction > 2) {
        //             let _currBreadStep = this.state.currBreadStep;
        //             this.setState({
        //                 currBreadStep: _currBreadStep < tAction ? _currBreadStep + 1 : _currBreadStep
        //             })
        //         }
        //     }, 1000);

        // }).then(() => {
        //     console.log("goodJob")
        // })

    }

    initBreath() {


        audioInit.play();

        new Promise(resolve => {
            this.breathCicle(resolve, this.state.action, this.state.steps[this.state.action])
        }).then(() => {
            // console.log("goodJob")
            if (globalInterval)
                clearInterval(globalInterval);

            if (globalResolveCount)
                globalResolveCount();
        })

        new Promise(resolve => {
            globalInterval = setInterval(() => {
                let action = this.state.action;
                let tAction = this.state.steps[action];
                if (tAction > 2) {
                    let _currBreadStep = this.state.currBreadStep;
                    this.setState({
                        currBreadStep: _currBreadStep < tAction ? _currBreadStep + 1 : _currBreadStep
                    })
                }
            }, 1000);
            globalResolveCount = resolve;

        }).then(() => {
            audioFinish.play();
        })
    }

    componentDidUpdate({ initBreathProscess: _initBreathProscess }) {
        const { limitTime, initBreathProscess } = this.props;

        if (limitTime !== 0 && !_initBreathProscess && initBreathProscess) {
            this.clearGlobals();
            this.initBreath();
        }
    }

    breathCicle(resolve, action, t) {
        const { limitTime, doit } = this.props;
        let _timeOut = null;
        let _actions = Object.keys(this.state.steps)
        let _indx = Object.keys(this.state.steps).indexOf(action) + 1;
        return new Promise(_resolve => {
            this.setState({
                action,
                currBreadStep: 1,
                actionText: t > 2 ? this.state.stepsText[action] : this.state.actionText
            })

            // if (t > 2)
            //     audioAction.play();

            _timeOut = setTimeout(() => {
                _resolve();
            }, t * 1000)
        }).then(() => {
            clearTimeout(_timeOut);
            let newAction = _actions[_indx] ? _actions[_indx] : _actions[0];
            let newActionTime = this.state.steps[newAction];
            let progress = moment(new Date()).diff(this.state.initTime, 'seconds');

            if (progress <= (limitTime * 60) && !doit) {
                this.breathCicle(resolve, newAction, newActionTime);
            } else {
                resolve();
            }

        })
    }

    clearGlobals() {
        if (globalTimeOut)
            clearTimeout(globalTimeOut)

        if (globalInterval)
            clearInterval(globalInterval);

        if (globalResolve)
            globalResolve()

        if (globalResolveCount)
            globalResolveCount()
    }

    componentWillUnmount() {
        this.clearGlobals();
    }


    render() {
        return (
            <div className="content-breath-indicator">

                {/* transform:  this.getScale() */}

                {/* className={this.getClassBreathAnimation()} */}
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"
                    className={`breath`} act={this.state.action}
                    style={{
                        animationDuration: `${this.state.steps[this.state.action]}s`
                    }}
                >


                    {indPaths.map((path, indx) => (
                        <BreathIndicatorPath key={`path_${indx}`} path={path} />
                    ))}

                    {/* <path fill="#FF0066" d={this.state.path} transform="translate(100 100)" /> */}
                </svg>
                <div>
                    <span className="breath-time">
                        {this.state.currBreadStep}
                        <small>S</small>
                    </span>
                    <span className="breath-action">{this.state.actionText}</span>
                </div>
            </div>
        )
    }
}




const mapStateToProps = state => ({
    actor: state.diagnosis.actor
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(breathIndicator);
