import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { personalInterst } from "../../resources/resources";

import { HOST_APP } from '../../constantsGlobal'

import QuestThumbEpyc from "./questThumbEpyc";
import QuestThumb from "./questThumb";
import FontAwesome from 'react-fontawesome';

class QuestiosThumbContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currInterest: [],
            breadPick: []
        }

        this.setObject = this.setObject.bind(this);
        this.setBackObject = this.setBackObject.bind(this);
        this.selectAnswer = this.selectAnswer.bind(this);
        this.getCurrObject = this.getCurrObject.bind(this);
        this.getCurrGrid = this.getCurrGrid.bind(this);
        this.addInterest = this.addInterest.bind(this);
    }


    selectAnswer(valor) {
        const { setAnser } = this.props;
        setAnser(valor);
    }

    setObject(name) {
        const { deepLevelBread } = this.props;
        let breadPick = this.state.breadPick;
        breadPick.push(name);
        this.setState({ breadPick })

        deepLevelBread(breadPick);
    }

    setBackObject() {
        const { deepLevelBread } = this.props;
        let breadPick = this.state.breadPick;
        breadPick.pop();
        this.setState({ breadPick })

        deepLevelBread(breadPick);
    }


    getCurrObject() {
        return this.state.breadPick.reduce((a, b) => a[b].values, personalInterst)
    }

    getCurrGrid(currObject) {
        return Object.keys(currObject)
            .map((interest, index) => {
                let isObject = typeof currObject[interest] == "object";
                return {
                    name: isObject ? currObject[interest].item : currObject[interest],
                    index: interest,
                    object: isObject ? currObject[interest].values : null,
                    type: typeof currObject[interest]
                }
            });
    }

    addInterest(name, checked, enabled) {

        const { setInterest } = this.props;

        let currInterest = this.state.currInterest;
        currInterest = currInterest.filter((item) => item.interest != name);

        if (!checked) {
            currInterest.push({
                interest: name,
                route: this.state.breadPick.join(",")
            });
        }

        this.setState({
            currInterest
        })

        setInterest(currInterest);
    }

    render() {
        const { question, actSelected } = this.props;

        let currObject = this.getCurrObject();
        let currGrid = this.getCurrGrid(currObject);

        let currInterest = this.state.currInterest.map((_interest) => _interest.interest);


        return (
            <div className="quest-thumb-grid" >
                <p>Selecciona las actividades que te generen interes.</p>

                {this.state.breadPick.length > 0 &&
                    <span onClick={() => this.setBackObject()} className="back-thumb">
                        {/* <img src={`${HOST_APP}./back3.png`}></img> */}

                        {/* className="incon-hint-ba" */}
                        <FontAwesome
                            name="arrow-left"
                        />
                    </span>
                }

                {currGrid.filter((interest) => interest.type == 'object').map((interest) => (
                    <QuestThumbEpyc setObject={() => this.setObject(interest.index)} name={interest.name} />
                ))}

                {currGrid.filter((interest) => interest.type != 'object' &&
                    Object.assign([], actSelected).indexOf(interest.name) == -1).map((interest) => (
                        <QuestThumbEpyc setObject={() => this.addInterest(interest.name, currInterest.indexOf(interest.name) > -1)} name={interest.name} checked={currInterest.indexOf(interest.name) > -1}
                        />
                    ))}

                {currGrid.filter((interest) => interest.type != 'object' &&
                    Object.assign([], actSelected).indexOf(interest.name) > -1).map((interest) => (
                        <QuestThumbEpyc setObject={() => { }} name={interest.name} disable={true} />
                    ))}

            </div>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestiosThumbContent);
