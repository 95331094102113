import {
    OPEN_MODAL_REDUCE, CLOSE_MODAL_REDUCE, SET_ALERT,
    UNSET_ALERT, SET_CONFIG_REDUCER, SET_USER_TOOLS_STATE_SAGA
} from '../constantsGlobal';

const initialState = {
    modalId: "",
    alertMessage: "",
    alertTime: 0,
    alertType: 'info',
    config: {},
    actorTools: {}
}

function utils(state = initialState, action) {
    switch (action.type) {
        case SET_USER_TOOLS_STATE_SAGA:
            // console.log(action.payload)
            return Object.assign({}, state, {
                actorTools: action.payload
            });
        case SET_CONFIG_REDUCER:
            return Object.assign({}, state, {
                config: action.payload
            });
        case OPEN_MODAL_REDUCE:
            return Object.assign({}, state, {
                modalId: action.payload
            });
        case CLOSE_MODAL_REDUCE:
            // console.log("close_modal")
            return Object.assign({}, state, {
                modalId: null
            });
        case SET_ALERT:
            // console.log("close_modal")
            return Object.assign({}, state, {
                alertMessage: action.payload.message ? action.payload.message : "",
                alertTime: action.payload.time ? action.payload.time : 0,
                alertType: action.payload.alertType ? action.payload.alertType : 'info'
            });
        case UNSET_ALERT:
            // console.log("close_modal")
            return Object.assign({}, state, {
                alertMessage: "",
                alertTime: 0,
                alertType: 'info'
            });


        default:
            return state
    }
}

export default utils;