import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import QuestionAnswer from './questionAnswer';

import AnswerSortable from './answerSortable';
import AnswerMatriz from './answerMatriz';

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null
        }

        this.selectAnswer = this.selectAnswer.bind(this);
        this.updateItems = this.updateItems.bind(this);
    }


    selectAnswer(valor) {
        const { setAnser } = this.props;
        // this.setState({
        //     value: valor
        // })
        setAnser(valor);
    }

    updateItems(items) {
        let resumeValues = items.map((item, index) => (`${item.id}-${index}`))
            .join(";");
        this.selectAnswer(resumeValues)
    }

    RenderInputUnicSelection(question) {
        return (
            <div>
                {question.p_respuestas && question.p_respuestas.map(resp =>
                    <div>
                        <input type="radio"
                            name={`question_${question.id}`}
                            value={resp.respuesta}
                            onChange={() => this.selectAnswer(resp.id)} />{resp.respuesta}<br />
                    </div>)}
            </div>
        )
    }

    render() {
        const { question, answers } = this.props;
        return (
            <section className="section-quest-item" >
                <p>{question.pregunta}</p>

                {/* Seleccion multiple */}
                {question.typeCampo == 1 &&
                    <div className="answer-content answer-content-vertical">
                        {question.p_respuestas && question.p_respuestas.map(resp =>
                            <label className={`${answers[question.id] == resp.id ? "active-radio" : ""} input-radio`} >
                                <input type="radio"
                                    name={`question_${question.id}`}
                                    value={resp.respuesta}
                                    onChange={() => this.selectAnswer(resp.id)} />
                                {resp.respuesta}
                            </label>
                        )}
                    </div>
                }

                {question.typeCampo == 2 &&
                    <div className="answer-content answer-content-horizontal ">
                        {question.p_respuestas && question.p_respuestas.map(resp =>
                            <label className={`${answers[question.id] == resp.id ? "active-radio" : ""} input-radio`} >
                                <input type="radio"
                                    name={`question_${question.id}`}
                                    value={resp.respuesta}
                                    onChange={() => this.selectAnswer(resp.id)} />{resp.respuesta}
                            </label>)}
                    </div>
                }

                {/* slider 1 -5 */}
                {question.typeCampo == 3 &&
                    <div className="answer-content">
                        <InputRange maxValue={5}
                            minValue={1}
                            step={1}
                            value={answers[question.id] ? answers[question.id] : 1}
                            onChange={value => this.selectAnswer(value)}
                        />

                        {/* <span className="hint-label left" >1</span>
                        <span className="hint-label rigth" >5</span>
                        <input type="range" min="1" max="5" step="1"
                            onChange={(ev) => this.selectAnswer(ev.currentTarget.value)} ></input> */}

                    </div>
                }
                {/* texto libre */}
                {question.typeCampo == 4 &&
                    <div className="answer-content">
                        <input type='text' value={answers[question.id]} onChange={(ev) => this.selectAnswer(ev.currentTarget.value)} />
                    </div>
                }

                {/* texto miltilinea */}
                {question.typeCampo == 5 &&
                    <div className="answer-content">
                        <textarea name="textarea" rows="5" onChange={(ev) => this.selectAnswer(ev.currentTarget.value)}>
                            {answers[question.id]}
                        </textarea>
                    </div>
                }

                {/* Numerico */}
                {question.typeCampo == 6 &&
                    <div className="answer-content">
                        <input type='number' value={answers[question.id]} onChange={(ev) => this.selectAnswer(ev.currentTarget.value)} />
                    </div>
                }

                {/* sort */}
                {question.typeCampo == 7 &&
                    <div className="answer-content">
                        {question.p_respuestas && question.p_respuestas &&
                            <AnswerSortable items={question.p_respuestas} updateItems={(itemsNew) => this.updateItems(itemsNew)} />
                        }
                    </div>
                }

                {/* matriz */}
                {question.typeCampo == 8 &&
                    <div className="answer-content">
                        {question.p_respuestas && question.p_respuestas &&
                            <AnswerMatriz
                                items={question.p_respuestas}
                                nItemsMatriz={5}
                                selectAnswer={(itemsNew) => this.selectAnswer(itemsNew)}
                                value={answers[question.id] ? answers[question.id] : ""}
                            />
                        }
                    </div>
                }

            </section>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Question);
