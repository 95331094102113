import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { HOST_APP, avatares, fingerPrint, anhelosdef, accionesEnea, habilidadesEsenciales } from '../constantsGlobal'

import { setInfoFooter } from '../actions/actions'

import { detalleAnhelos, } from '../resources/resources'


import FA from 'react-fontawesome';

import ColumnChart from './charts/columnChart'

class Ecosistemic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidate: {},
            predominante: {},
            chartAnhelo: [],
            modalAsertOpen: false,
            showModal: false,
            viewHability: ""
        }
    }


    componentWillMount() {
        const { answers, anhelos, actor, history, setInfoFooter } = this.props;

        if (!actor.id) {
            history.push("/");
        }


    }


    render() {


        return (
            <div className="content-profile">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"

                }} >
                    <h2>En construcción</h2>
                    <FA name="truck" style={{ color: "white", fontSize: "22pt" }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    eneaQuestios: state.diagnosis.eneaQuestios
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setInfoFooter
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Ecosistemic);
