import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { unsetAlert } from '../actions/actions';

import { HOST_APP } from '../constantsGlobal'

let timeOut = null;

class Alert extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate({ alertMessage: alertMessagePrev }) {
        const { alertMessage, alertTime, unsetAlert } = this.props;
        // if (alertMessagePrev != alertMessage && !!alertMessage) {
        //     if (timeOut)
        //         clearTimeout(timeOut);

        //     timeOut = setTimeout(() => {
        //         unsetAlert()
        //     }, alertTime)
        // }
    }

    render() {
        const { alertMessage } = this.props;
        return (
            <div style={{ backgroundImage: `url(${HOST_APP}degradados/degradado_trans_top.png)` }}
                className={`alert ${''} ${alertMessage ? "show" : "hide"}`}>
                <h5>{alertMessage}</h5>
                <img src={`${HOST_APP}ICONO.png`}></img>
                <hr />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    alertMessage: state.utils.alertMessage,
    alertTime: state.utils.alertTime,
    alertType: state.utils.alertType,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        unsetAlert
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Alert);
