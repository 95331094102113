import { combineReducers } from 'redux'
import diagnosis from './diagnosis'
import footer from './footer'
import utils from './states'
import feedback from './feedback'
import definition from './definition'
import saturation from './saturation'

const reducers = combineReducers({
    diagnosis,
    footer,
    utils,
    feedback,
    definition,
    saturation
});

export default reducers;