import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Question from './questions/question';

import QuestiosThumbContent from './questions/questiosThumbContent'
import QuestThumb from './questions/questThumb'




import { saveAnswers, setInterestActor, updateInitInfoActor, setEneaActor, setInfoFooter, enableFooterNextValue, disableFooterNextValue } from '../actions/actions';
import Redirect from '../utils/redirect';


import AtomicQuestion from './questions/atomicQuestion'

import { questionsDesatend, HOST_APP, getAnheloPersona, anhelosdef } from '../constantsGlobal'

import FA from 'react-fontawesome';


class QuestionsContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {},
            // interestAct: [{ "interest": "football", "route": "Deportes,en equipo" }, { "interest": "tenis", "route": "Deportes,en equipo" }, { "interest": "ping pong", "route": "Deportes,en equipo" }, { "interest": "whater polo", "route": "Deportes,en equipo" }, { "interest": "escalar", "route": "Deportes,con amigos o solo" }, { "interest": "artes marciales", "route": "Deportes,con amigos o solo" }, { "interest": "yoga", "route": "Deportes,con amigos o solo" }, { "interest": "formacion", "route": "Literatura" }, { "interest": "pulp fiction", "route": "Literatura" }, { "interest": "misterio", "route": "Literatura" }, { "interest": "picaresca", "route": "Literatura" }, { "interest": "terror", "route": "Literatura" }, { "interest": "Ahorro de agua", "route": "Ambiente" }, { "interest": "Siembra de Arboles", "route": "Ambiente" }, { "interest": "Termos de agua", "route": "Ambiente" }, { "interest": "Mugs", "route": "Ambiente" }, { "interest": "Reciclaje", "route": "Ambiente" }, { "interest": "baile", "route": "Arte" }, { "interest": "arte plastico", "route": "Arte" }, { "interest": "fotografia", "route": "Arte" }],
            interestAct: [],
            interestInt: [],
            stept: 1,
            messajes: {
                "1": "Ayúdanos respondiendo la siguiente encuesta.",
                "2": "Queremos conocerte un poco más, cuéntanos qué actividades llaman tu atención.",
                "3": "Genial, ya casi terminamos!, selecciona las actividades que realizas actualmente."
            },
            isLevelBreadBase: true,
            showModal: true,
            genero: "",
            huella: "",
            avatar: "",
            enea: "",
            temores: []
        };

        this.setAnswers = this.setAnswers.bind(this);
        this.saveAnswers = this.saveAnswers.bind(this);
        this.setInterestAct = this.setInterestAct.bind(this);
        this.setInterestInt = this.setInterestInt.bind(this);

        this.selectTemor = this.selectTemor.bind(this);
        this.getAnhelo = this.getAnhelo.bind(this);
        this.validateContent = this.validateContent.bind(this);
    }

    componentWillMount() {
        const { actor, history, setInfoFooter } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        setInfoFooter({
            type: "steeps",
            childs: [
                { name: "Atras", value: "prev", getVisibility: (step) => (step > 1) },
                { name: "Siguiente", value: "next", getVisibility: (step) => (step < 8) },
                { name: "Guardar", value: "save", getVisibility: (step) => (step == 8) }
            ],
            value: 1
        })

        // window.onbeforeunload = function() { return "Your work will be lost."; };

        if (window.localStorage.answers) {
            this.setState({
                answers: JSON.parse(window.localStorage.answers)
            })
        }
        if (window.localStorage.infoContact) {
            let _infoContact = JSON.parse(window.localStorage.infoContact);
            let dataStorage = {};

            Object.keys(_infoContact).forEach(item => {
                dataStorage[item] = _infoContact[item];
            })

            this.setState(dataStorage);
        }

        if (window.localStorage.interestAct) {
            let _interestAct = JSON.parse(window.localStorage.interestAct);
            this.setState({
                interestAct: _interestAct
            });
        }

        if (window.localStorage.interestInt) {
            let _interestInt = JSON.parse(window.localStorage.interestInt);
            this.setState({
                interestInt: _interestInt
            });
        }





    }

    saveAnswers() {
        const { saveAnswers, actor, questios, setInterestActor, updateInitInfoActor } = this.props;

        let typesCampos = questios.reduce((a, b) => {
            a[b.id] = b.typeCampo;
            return a;
        }, {});

        let _answers = Object.keys(this.state.answers)
            .map((idQuestion) => {
                let isValue = !(typesCampos[idQuestion] == 1 || typesCampos[idQuestion] == 2);
                return {
                    respuesta: this.state.answers[idQuestion],
                    idRespuesta: !isValue ? this.state.answers[idQuestion] : null,
                    idPregunta: idQuestion,
                    idActor: actor.id
                }
            })

        saveAnswers(_answers);

        let _interests = [];


        this.state.interestAct.forEach((interest) => {
            _interests.push({
                idActor: actor.id,
                interest: interest.interest,
                priority: 1,
                route: interest.route
            })
        });
        this.state.interestInt.forEach((interest) => {
            _interests.push({
                idActor: actor.id,
                interest: interest.interest,
                priority: 0,
                route: interest.route
            })
        });

        if (_interests.length > 0)
            setInterestActor({
                idActor: actor.id,
                interests: _interests
            });

        updateInitInfoActor({
            enea: this.state.enea,
            avatar: this.state.avatar,
            genero: this.state.genero,
            huella: this.state.huella,
            actorId: actor.id,
            code: actor.codigo
        })


    }

    setAnswers(idAnswer, idQuestion) {
        const { questios, enableFooterNextValue } = this.props;
        let answers = Object.assign({}, this.state.answers);
        answers[idQuestion] = idAnswer;

        localStorage.setItem('answers', JSON.stringify(answers));

        this.setState({
            answers: answers
        });

        // let hallowNext = questios.reduce((a, b) => {
        //     return a * (answers[String(b.id)] && answers[String(b.id)] != "" ? 1 : 0)
        // }, 1)

        // if (hallowNext == 1) {
        //     enableFooterNextValue();
        // }
    }

    setInterestAct(inte) {
        const { enableFooterNextValue } = this.props;
        localStorage.setItem('interestAct', JSON.stringify(inte));
        this.setState({
            interestAct: inte
        });
        // enableFooterNextValue();
    }

    setInterestInt(inte) {
        const { enableFooterNextValue } = this.props;
        localStorage.setItem('interestInt', JSON.stringify(inte));
        this.setState({
            interestInt: inte
        });
        // enableFooterNextValue();
    }


    openModal(componente) {
        this.setState({
            showModal: true,
            currentModalComponent: componente
        })
    }

    setInfo(key, value) {
        const { close, updateInitInfoActor, actor, enableFooterNextValue } = this.props;

        let _value = {};
        _value[key] = value;
        this.setState(_value)

        let dataStorage = {
            enea: this.state.enea,
            avatar: this.state.avatar,
            genero: this.state.genero,
            huella: this.state.huella,
        }
        dataStorage[key] = value;
        localStorage.setItem('infoContact', JSON.stringify(dataStorage));


        // enableFooterNextValue();
    }

    selectTemor(eneatipo) {
        const { actor, setEneaActor } = this.props;

        this.setState({
            enea: eneatipo
        })
        // setEneaActor({
        //     enea: eneatipo,
        //     actorId: actor.id,
        //     code: actor.codigo
        // })
    }

    getAnhelo() {
        const { anhelos, eneaQuestios, setInfoFooter, steep } = this.props;

        let _answersRaw = Object.keys(this.state.answers).map(idQuestion => ({ idRespuesta: this.state.answers[idQuestion] }));

        let consolidate = getAnheloPersona(_answersRaw, anhelos);

        debugger;

        let _anheloPred = consolidate.predominante.anhelo;

        let temores = eneaQuestios.filter(item => !!anhelosdef[_anheloPred] && item.anhelonumber == anhelosdef[_anheloPred].anhelonumber);
        this.setState({
            temores
        })
    }

    validateContent() {
        const { steep, questios, enableFooterNextValue, disableFooterNextValue } = this.props;
        let hallowNext = true;
        switch (steep) {
            case 2:
                hallowNext = this.state.genero != "";
                break;
            case 3:
                hallowNext = this.state.huella != "";
                break;
            case 4:
                hallowNext = this.state.avatar != "";
                break;
            case 5:
                let _allow = questios.filter(item => item.typeCampo != 7).reduce((a, b) => {
                    return a * (this.state.answers[String(b.id)] && this.state.answers[String(b.id)] != "" ? 1 : 0)
                }, 1);

                hallowNext = _allow == 1;
                break;
            case 6:
                hallowNext = this.state.interestAct.length > 0;
                break;
            case 7:
                hallowNext = this.state.interestInt.length > 0;
                break;
            default:
                break;
        }

        if (hallowNext) {
            enableFooterNextValue();
        } else {
            disableFooterNextValue()
        }

        return true;
    }



    render() {
        const { actor, questios, history, answers, steep } = this.props;

        this.validateContent();
        let _steptSequence = 1;
        return (
            <section className="section-quest" >

                {steep == (_steptSequence++) &&
                    <div className="welcolme-content">
                        <h1>Hola {actor.nombre}</h1>
                        <p>{this.state.messajes[steep]}</p>
                    </div>
                }

                {steep == (_steptSequence++) &&

                    <AtomicQuestion
                        gridView={true}
                        questio={questionsDesatend.genero.title}
                        options={questionsDesatend.genero.options.map(option => (
                            <div className={this.state.genero == option.value ? "atomic-quest-selected" : ""}
                                onClick={() => this.setInfo("genero", option.value)}>
                                <img src={`${HOST_APP}${option.img}`} />
                                <span>{option.name}</span>
                            </div>
                        ))}
                    />
                }

                {steep == (_steptSequence++) &&

                    <AtomicQuestion
                        gridView={true}
                        questio={`La huella de tu indice ${this.state.genero == "M" ? "izquierdo" : "derecho"} se parece a`}
                        options={questionsDesatend.fingerPrint.options.map(option => (
                            <img className={this.state.huella == option.value ? "atomic-quest-selected" : ""}
                                onClick={() => this.setInfo("huella", option.value)} src={`${HOST_APP}${option.img}`} />
                        ))}
                    />
                }

                {steep == (_steptSequence++) &&

                    <AtomicQuestion
                        gridView={true}
                        questio={questionsDesatend.avatar.title}
                        options={questionsDesatend.avatar.options.map(option => (
                            <img className={this.state.avatar == option.value ? "atomic-quest-selected" : ""}
                                onClick={() => this.setInfo("avatar", option.value)} src={`${HOST_APP}${option.img}`} />
                        ))}
                    />
                }

                {steep == (_steptSequence++) &&
                    <div className="content-questions">
                        {questios.map(quest => <Question answers={this.state.answers} setAnser={(idAnswer) => this.setAnswers(idAnswer, quest.id)} question={quest} />)}
                    </div>
                }

                {steep == (_steptSequence++) &&
                    <QuestiosThumbContent deepLevelBread={(breadPick) => this.setState({
                        isLevelBreadBase: breadPick.length == 0
                    })} setInterest={(interest) => this.setInterestAct(interest)} />
                }

                {steep == (_steptSequence++) &&
                    <QuestThumb setInterest={(interest) => this.setInterestInt(interest)} interests={this.state.interestAct} />
                }

                {steep == (_steptSequence) &&
                    <AtomicQuestion
                        onLoadComponent={() => this.getAnhelo()}
                        questio={questionsDesatend.fears.title}
                        options={this.state.temores.map((items) => (
                            <span className={this.state.enea == items.enatipo ? "active" : ""} onClick={() => this.selectTemor(items.enatipo)} >{items.pregunta}</span>
                        ))}
                    />
                }

                {/* 
                {steep < _steptSequence && this.state.isLevelBreadBase &&
                    <button onClick={() => {
                        this.setState({
                            stept: ++steep
                        })
                    }} style={{ color: "white" }}>Continuar</button>
                }

                {steep == _steptSequence && this.state.isLevelBreadBase &&
                    <button onClick={this.saveAnswers}
                        style={{ color: "white" }}
                    >Guardar</button>
                } */}

                {steep === "saveinfo" && <div className="welcolme-content">
                    <FA
                        className="spin-question"
                        name="spinner"
                        size="2x"
                        spin
                        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color: "white" }}
                    />
                </div>}

                {steep === "saveinfo" && this.saveAnswers()}


                {answers && answers.length > 0 && <Redirect currentHistory={history} route={'/home/profile/'} />}
                {/* {answers && answers.length > 0 && <Redirect currentHistory={history} route={'/home/dashboard/'} />} */}

                {/* {actor.typeActor != 0 && this.state.showModal && actor.huella == 0 && <ModalInfo type={"padding-0"} bodyConten={(
                    <InitInfoActor close={() => this.setState({ showModal: false })} />
                )} />} */}



            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    eneaQuestios: state.diagnosis.eneaQuestios,
    steep: state.footer.value,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAnswers,
        setInterestActor,
        updateInitInfoActor,
        setEneaActor,
        setInfoFooter,
        enableFooterNextValue,
        disableFooterNextValue
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestionsContent);
