import React, { Component } from 'react';
import { connect } from 'react-redux';

import { anhelosdef, accionesEnea, enumFeedbackContext } from '../constantsGlobal'


class Retrospective extends Component {

    componentWillMount() {
        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }
    }

    render() {
        const { actor } = this.props;
        return (
            <div className="content-retrospective">
                <h2>Comentarios retrospectiva Biotica</h2>
                <div className="content-comments">
                    {accionesEnea[actor.eneatipo] && accionesEnea[actor.eneatipo].map(item =>
                        <div>
                            <span>{item}</span>
                        </div>
                    )}

                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    actor: state.diagnosis.actor
});
const mapDispatchToProps = dispatch => { };


export default connect(mapStateToProps, mapDispatchToProps)(Retrospective);
